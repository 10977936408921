// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {FilteredClientsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useQuery,
  useNavigationDOM,
  usePagination,
  useForm,
  useEffect,
  useResponsive,
  useRef,
} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ClientsFilters from 'modules/Client/components/ClientsFilters';
import ClientsList from 'modules/Client/components/ClientsList';
import NewClientModal from 'modules/Client/components/NewClientModal';

const ContentContainer = Styled.View`
  flex: 1;
  margin-horizontal: 24px;
`;

const Content = Styled.View`
  width: 100%;
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const getCategoryFilters = (category) => {
  const mainCategoryFilter = FilteredClientsForm.URL_TO_VARIABLE_MAP[category];
  if (mainCategoryFilter === 'ALL') {
    // Return all categories
    return FilteredClientsForm.ALL_CATEGORIES;
  } else {
    return [mainCategoryFilter];
  }
};

const getVariablesFromParams = (params, organization) => {
  const {category, salespersonIds, ...rest} = params;
  // Redirect to All if no category is specified in URL
  const urlCategory = category || 'all';
  const projectCategories = getCategoryFilters(urlCategory);
  const correctedSalespersonIds = URL.decodeEmptyStringToNull(salespersonIds);
  return {
    projectCategories,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    category: urlCategory,
    slugs: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
    salespersonIds: correctedSalespersonIds,
    ...rest,
  };
};

const getUrlWithVariables = (variables) => {
  // Remove category, which is stored in the route, not the query params
  const {projectCategories, ...rest} = variables;

  const baseRoute = `/clients/customers`;
  return URL.getUrlFromVariables(baseRoute, rest);
};

const ListClientsPageContent = ({organization, refetch}) => {
  const responsive = useResponsive();
  const ref = useRef();
  const newClientModal = useModal();
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params, organization);
  const shouldRedirect = !params.pagination || !params.category || !params.slugs;
  const form = useForm({
    initialValues: {
      filteredClientsForm: FilteredClientsForm.toForm(variables),
    },
  });

  const {loading, data, error} = useQuery(ListClientsPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables,
    skip: shouldRedirect,
  });

  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page),
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.filteredClientsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      navigator.replace(
        getUrlWithVariables({
          ...variables,
          pagination: {
            ...variables.pagination,
            page,
          },
        }),
      );
    },
  });

  useEffect(() => {
    const queryParams = FilteredClientsForm.toQueryParams(form.values.filteredClientsForm);

    if (shouldRedirect) {
      navigator.replace(getUrlWithVariables(variables));
      return;
    }

    if (!ref.current) {
      // Set the initial ref to avoid unnecessary URL updates
      ref.current = queryParams;
      return;
    }

    // Only push new URL when variables have changed
    if (!_.isEqual(ref.current, queryParams)) {
      ref.current = queryParams;
      const url = getUrlWithVariables({
        ...queryParams,
        pagination: PaginationBar.DEFAULT_PAGINATION,
      });
      navigator.replace(url);
      return;
    }
  }, [form, navigator, ref, variables, params, shouldRedirect]);

  // This case handles error cases when we skip the query due to missing params
  if (shouldRedirect) {
    return <PageLoadingIndicator />;
  }
  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }
  return (
    <ContentContainer>
      <Space height={16} />
      <Section sectionIndex={0}>
        <Content {...responsive}>
          <ClientsFilters
            form={form}
            organization={organization}
            refetch={refetch}
            clientCountByCategory={data.viewer.viewingOrganization.clientCountByCategory}
          />
        </Content>
      </Section>
      <ScrollView style={{flex: 1}}>
        <Content {...responsive}>
          <Section sectionIndex={1}>
            <ClientsList
              loading={loading}
              clients={
                data ? data.viewer.viewingOrganization.filteredClientsPaginatedList.clients : []
              }
              isEnabledClientMultibranchMigration={
                organization.features.isEnabledClientMultibranchMigration
              }
              isPrimaryOrganization={organization.isPrimary}
              refetch={refetch}
              hasError={!!error}
            />
          </Section>
          <Space height={40} />
          <Section sectionIndex={1}>
            <PaginationBar pagination={pagination} />
          </Section>
        </Content>
      </ScrollView>
      <NewClientModal
        key={newClientModal.key}
        isOpen={newClientModal.isOpen}
        handleClose={newClientModal.handleClose}
        refetch={refetch}
      />
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListClientsPageContent.query = gql`
  ${ClientsList.fragment}
  ${usePagination.fragment}
  query ListClientsPageContent(
    $pagination: PaginationInput!,
    $salespersonIds: [Int],
    $projectCategories: [String],
    $searchQuery: String,
    $sortSettings: [ProjectSortSetting],
    $slugs: [String],
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        clientCountByCategory(
          salespersonIds: $salespersonIds,
          searchQuery: $searchQuery,
          sortSettings: $sortSettings,
          slugs: $slugs,
        ) {
          allCount
          movesCount
          storageCount
        }
        filteredClientsPaginatedList(
          pagination: $pagination,
          projectCategories: $projectCategories,
          salespersonIds: $salespersonIds,
          searchQuery: $searchQuery,
          sortSettings: $sortSettings,
          slugs: $slugs,
        ) {
          clients: results {
            id
            ...ClientsList
          }
          paginationMetadata {
            ...usePagination
          }
        }
      }
    }
  }
`;

ListClientsPageContent.fragment = gql`
  ${Organization.getSalespersonOptions.fragment}
  ${ClientsFilters.fragment}

  fragment ListClientsPageContent on Organization {
    id
    slug
    isPrimary
    clientCount
    canViewOtherBranchesData
    features {
      isEnabledClientMultibranchMigration: isEnabled(feature: "CLIENT_MULTIBRANCH_MIGRATION")
    }
    ...ClientsFilters
    ...Organization_getSalespersonOptions
  }
`;

export default ListClientsPageContent;
