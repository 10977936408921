// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {FilteredProjectsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useResponsive,
  useNavigationDOM,
  useQuery,
  useEffect,
  useMountEffect,
  usePagination,
  useRef,
  useForm,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import useManagerRestriction from 'modules/App/hooks/useManagerRestriction';
import ListProjectsPageFilters from 'modules/Project/List/components/ListProjectsPageFilters';
import ListProjectsPageSalesStatusFilter from 'modules/Project/List/components/ListProjectsPageSalesStatusFilter';
import ProjectsList from 'modules/Project/components/ProjectsList';

const Container = Styled.View`
  flex: 1;
  margin-horizontal: 24px;
`;

const Content = Styled.View`
  width: 100%;
`;

const ListContainer = Styled.View`
  width: 100%;
  padding: 16px;
  background-color: ${colors.white}
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const getStatusFilters = (status, isEnabledProjectHoldStatus) => {
  const mainStatusFilter = FilteredProjectsForm.URL_TO_VARIABLE_MAP[status];
  if (!isEnabledProjectHoldStatus && mainStatusFilter === 'LEAD') {
    // If the holds flag is turned off, then all "holds" act as "leads"
    return [mainStatusFilter, ProjectStatus.HOLD];
  } else if (mainStatusFilter === 'ACTIVE') {
    // Return all active statuses, excluding cancelled
    return FilteredProjectsForm.ACTIVE_STATUSES;
  } else {
    return [mainStatusFilter];
  }
};

const getVariablesFromParams = (
  params,
  isEnabledProjectHoldStatus,
  isEnabledSalesStatusDepositReceived,
) => {
  const {status, salespersonIds, ...rest} = params;
  // Redirect to leads if no status is specified in URL
  const urlStatus = status || 'leads';
  const statuses = getStatusFilters(urlStatus, isEnabledProjectHoldStatus);
  const defaultSalesStatuses = FilteredProjectsForm.getAvailableSalesStatuses({
    status: urlStatus,
    isEnabledProjectHoldStatus,
    isEnabledSalesStatusDepositReceived,
  });
  // When URL encoded, null turns into empty string. Correct this by turning it back
  const correctedSalespersonIds = URL.decodeEmptyStringToNull(salespersonIds);

  return {
    statuses,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    salesStatuses: defaultSalesStatuses.length ? defaultSalesStatuses : null,
    status: urlStatus,
    salespersonIds: correctedSalespersonIds,
    ...rest,
  };
};

const getUrlWithVariables = (variables) => {
  const {statuses, ...rest} = variables;

  const baseRoute = `/moves/list`;
  return URL.getUrlFromVariables(baseRoute, rest);
};

const ListProjectsPageContent = ({organization, jobRequestsCount}) => {
  const responsive = useResponsive();
  const queryParams = useRef();
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(
    params,
    organization.features.isEnabledProjectHoldStatus,
    organization.features.isEnabledSalesStatusDepositReceived,
  );
  const form = useForm({
    initialValues: {
      filteredProjectsForm: FilteredProjectsForm.toForm(variables),
    },
  });

  const {loading, data, refetch, error} = useQuery(ListProjectsPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  useMountEffect(() => {
    // If required params are not set in the route on mount,
    // reload the page with the correct variables
    if (!params.pagination || !params.status || !params.salesStatuses) {
      navigator.replace(getUrlWithVariables(variables));
    }
  });

  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page), // The URL is the single source of truth for currentPage
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      const url = getUrlWithVariables({
        ...variables,
        pagination: {
          page,
          resultsPerPage: variables.pagination.resultsPerPage,
        },
      });
      navigator.push(url);
    },
  });

  // Set salespersons filter to viewer if not an admin. The backend also handles the
  // salespersonIds this way when querying and will only return results for the current
  // user if the user is a salesperson. Handling it here in the url however allows the
  // salesperson to share their link with admins and show the exact same results.
  const {isRestricted, viewerId} = useManagerRestriction({
    isBypassed: organization.features.isEnabledSalespersonViewAllProjects,
    handleRestriction: (userId) => {
      form.setFieldValue('filteredProjectsForm.salespersonIds', [userId]);
    },
  });

  useEffect(() => {
    const newQueryParams = FilteredProjectsForm.toQueryParams(form.values.filteredProjectsForm);
    if (!queryParams.current) {
      // Set the initial ref to avoid unnecessary URL updates
      queryParams.current = newQueryParams;
      return;
    }

    // Only push new URL when variables have changed
    if (!_.isEqual(queryParams.current, newQueryParams)) {
      queryParams.current = newQueryParams;
      const url = getUrlWithVariables({
        ...newQueryParams,
        pagination: PaginationBar.DEFAULT_PAGINATION,
      });
      navigator.replace(url);
    }
  }, [form, navigator, queryParams, variables]);

  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }
  const isFilteringCancelledProjects = form.values.filteredProjectsForm.status === 'cancelled';

  return (
    <Container>
      <Space height={16} />
      <Section sectionIndex={0}>
        <Content {...responsive}>
          <ListProjectsPageFilters
            form={form}
            organization={organization}
            jobRequestsCount={jobRequestsCount}
            filteredProjectCountsByStatus={data.filteredProjectCountsByStatus}
            isRestricted={isRestricted}
            viewerId={viewerId}
          />
        </Content>
      </Section>
      <ScrollView style={{flex: 1}}>
        <Content {...responsive}>
          <ListContainer>
            <Section sectionIndex={0}>
              <ListProjectsPageSalesStatusFilter
                status={variables.status}
                form={form}
                filteredProjectCountsBySalesStatus={data.filteredProjectCountsBySalesStatus}
                isEnabledProjectHoldStatus={organization.features.isEnabledProjectHoldStatus}
                isEnabledSalesStatusDepositReceived={
                  organization.features.isEnabledSalesStatusDepositReceived
                }
              />
            </Section>
            <Section sectionIndex={1}>
              <ProjectsList
                status={variables.status}
                loading={loading}
                refetch={refetch}
                projects={data ? data.paginatedList.projects : []}
                hasError={!!error}
                isEnabledProjectDetailsFollowUp={
                  organization.features.isEnabledProjectDetailsFollowUp
                }
                isFilteredByFollowUpDate={
                  form.values.filteredProjectsForm.followUpStartDate ||
                  form.values.filteredProjectsForm.followUpEndDate
                }
                isEnabledMovesListMultiBranchSupport={
                  organization.features.isEnabledMovesListMultiBranchSupport
                }
                isPrimaryOrganization={organization.isPrimary}
                isFilteringCancelledProjects={isFilteringCancelledProjects}
                isEnabledProjectTag={organization.features.isEnabledProjectTag}
              />
            </Section>
            <Space height={40} />
            <Section sectionIndex={2}>
              <PaginationBar pagination={pagination} />
            </Section>
            <Space height={30} />
          </ListContainer>
          <Space height={70} />
        </Content>
      </ScrollView>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPageContent.query = gql`
  ${ProjectsList.fragment}
  ${usePagination.fragment}
  ${ListProjectsPageFilters.countsFragment}
  ${ListProjectsPageSalesStatusFilter.countsFragment}

  query ListProjectsPageContent(
    $pagination: PaginationInput!,
    $statuses: [String],
    $salesStatuses: [String],
    $salespersonIds: [Int],
    $coordinatorIds: [Int],
    $fromDate: String,
    $toDate: String,
    $searchQuery: String,
    $followUpStartDate: String,
    $followUpEndDate: String,
    $sortSettings: [ProjectSortSetting],
    $projectTypeCategory: String,
    $crewOrganizationIds: [String],
    $projectTypeIds: [Int],
    $projectTagIds: [Int],
    $referralSources: [String],
  ) {
    ${gql.query}
    filteredProjectCountsByStatus(
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      followUpStartDate: $followUpStartDate,
      followUpEndDate: $followUpEndDate,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      crewOrganizationIds: $crewOrganizationIds,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      referralSources: $referralSources,
    ) {
      ...ListProjectsPageFilters_countsFragment
    }
    filteredProjectCountsBySalesStatus(
      statuses: $statuses,
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      followUpStartDate: $followUpStartDate,
      followUpEndDate: $followUpEndDate,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      crewOrganizationIds: $crewOrganizationIds,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      referralSources: $referralSources,
    ) {
      ...ListProjectsPageSalesStatusFilter_countsFragment
    }
    paginatedList: filteredProjectsPaginatedList(
      pagination: $pagination,
      statuses: $statuses,
      salesStatuses: $salesStatuses,
      salespersonIds: $salespersonIds,
      coordinatorIds: $coordinatorIds,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      followUpStartDate: $followUpStartDate,
      followUpEndDate: $followUpEndDate,
      sortSettings: $sortSettings,
      projectTypeCategory: $projectTypeCategory,
      crewOrganizationIds: $crewOrganizationIds,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      referralSources: $referralSources,
    ) {
      projects: results {
        id
        ...ProjectsList
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

ListProjectsPageContent.fragment = gql`
  ${ListProjectsPageFilters.fragment}
  ${ListProjectsPageSalesStatusFilter.fragment}

  fragment ListProjectsPageContent on Organization {
    id
    isPrimary
    features {
      isEnabledProjectHoldStatus: isEnabled(feature: "PROJECT_HOLD_STATUS")
      isEnabledProjectDetailsFollowUp: isEnabled(feature: "PROJECT_DETAILS_FOLLOW_UP")
      isEnabledSalesStatusDepositReceived: isEnabled(feature: "SALES_STATUS_DEPOSIT_RECEIVED")
      isEnabledMovesListMultiBranchSupport: isEnabled(feature: "MOVES_LIST_MULTI_BRANCH_SUPPORT")
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
      isEnabledSalespersonViewAllProjects: isEnabled(feature: "SALESPERSON_VIEW_ALL_PROJECTS")
    }
    ...ListProjectsPageFilters
    ...ListProjectsPageSalesStatusFilter
  }
`;

export default ListProjectsPageContent;
