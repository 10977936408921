// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldValueExpandable from 'modules/Project/V2/Show/Blocks/components/FieldValueExpandable';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const FlexContainer = Styled.View`
  flex: ${(props) => props.flex};
`;

const CaptionText = Styled.Text`
  ${Typography.Body4}
  color: ${(props) => (props.color ? props.color : colors.gray.secondary)};
`;

const BillItemName = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
  flex: 4;
`;

const BillItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const BillItemDetailText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => props.color};
  text-align: right;
`;

const MobileBillItemText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => props.color};
`;

const QuestionIconCircle = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${colors.orange.status};
  align-items: center;
  justify-content: center;
`;

const ListEmptyMessageContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  padding: 8px;
  align-items: center;
`;

const ListEmptyActionText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.orange.status};
`;

const BillItemDetail = ({icon, children, color, flex}) => {
  return (
    <BillItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <BillItemDetailText color={color}>{children}</BillItemDetailText>
      </Row>
    </BillItemDetailContainer>
  );
};

const DiscountTag = () => {
  return (
    <React.Fragment>
      <Icon
        source={Icon.Tag}
        size={10}
        // TODO(dan) Find a cross platform solution for rotating icons
        style={{transform: 'rotate(90deg)', alignSelf: 'center'}}
        color={colors.green.status}
      />
      <Space width={4} />
    </React.Fragment>
  );
};

const QuantityColumn = ({billItem, isHighlighted, isDocument, isEnabledTbdBillItems}) => {
  const quantity = BillItem.getEstimateQuantity(billItem, {isEnabledTbdBillItems});
  const responsive = useResponsive();
  if (responsive.mobile && !isDocument) {
    return <MobileBillItemText color={colors.gray.tertiary}>{quantity}</MobileBillItemText>;
  }
  return (
    <BillItemDetail color={isHighlighted ? colors.gray.secondary : colors.gray.secondary} flex={2}>
      {quantity}
    </BillItemDetail>
  );
};

const PriceColumn = ({
  billItem,
  isTotalVisible,
  isHighlighted,
  isDocument,
  isEnabledTbdBillItems,
}) => {
  const responsive = useResponsive();
  const price = BillItem.getDisplayPrice(billItem, {isEnabledTbdBillItems});
  if (responsive.mobile && !isDocument) {
    return <MobileBillItemText color={colors.gray.tertiary}>{price}</MobileBillItemText>;
  }
  return (
    <BillItemDetail
      color={isHighlighted ? colors.gray.secondary : colors.gray.secondary}
      flex={isTotalVisible ? 2 : 3}
    >
      {price}
    </BillItemDetail>
  );
};

const TotalColumn = ({billItem, isDocument, isEnabledTbdBillItems}) => {
  const responsive = useResponsive();
  const total = BillItem.getEstimateTotal(billItem, {isEnabledTbdBillItems});
  const totalColor = billItem.isCredit ? colors.green.status : colors.gray.primary;
  if (responsive.mobile && !isDocument) {
    return (
      <React.Fragment>
        {billItem.isCredit && <DiscountTag />}
        <MobileBillItemText color={totalColor}>{total}</MobileBillItemText>
      </React.Fragment>
    );
  }
  return (
    <BillItemDetail flex={3} color={totalColor} icon={billItem.isCredit ? <DiscountTag /> : null}>
      {total}
    </BillItemDetail>
  );
};

const FlexSpaceHolder = ({isQuantityVisible, isTotalVisible}) => {
  // This fills any additional space created from fields being disabled.
  // For every field we take away we add to the flex value to evenly compensate.
  let totalFlexSpace = 0;
  if (!isQuantityVisible) {
    totalFlexSpace += 2;
  }
  if (!isTotalVisible) {
    totalFlexSpace += 2;
  }
  return <BillItemDetailContainer flex={totalFlexSpace} />;
};

const Description = ({description}) => {
  return (
    <Row>
      <FlexContainer flex={5}>
        {!!description && <Space height={2} />}
        <CaptionText color={colors.gray.secondary}>{description}</CaptionText>
      </FlexContainer>
      <FlexContainer flex={6} />
    </Row>
  );
};

const MobileDescription = ({
  billItem,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
}) => {
  const billItemDescription = _.get(billItem, descriptionField);
  if (!billItemDescription) {
    return null;
  }

  return (
    <React.Fragment>
      <Space height={4} />
      <FieldValueExpandable
        data={billItem}
        property={descriptionField}
        numberOfLines={4}
        style={{flex: undefined}}
        isTruncated={
          truncated[getTruncatedKey ? getTruncatedKey({billItemId: billItem.id}) : descriptionField]
        }
        isEnabledToggle={
          enabledToggle[
            getTruncatedKey ? getTruncatedKey({billItemId: billItem.id}) : descriptionField
          ]
        }
        setTruncated={setTruncated}
        setEnabledToggle={setEnabledToggle}
        truncatedKey={getTruncatedKey ? getTruncatedKey({billItemId: billItem.id}) : undefined}
      />
    </React.Fragment>
  );
};

const MobileDescriptionWithState = ({billItem}) => {
  const descriptionField = 'description';
  const [truncated, setTruncated] = useState({[descriptionField]: true});
  const [enabledToggle, setEnabledToggle] = useState({[descriptionField]: false});

  const billItemDescription = _.get(billItem, descriptionField);
  if (!billItemDescription) {
    return null;
  }

  return (
    <MobileDescription
      billItem={billItem}
      descriptionField={descriptionField}
      truncated={truncated}
      setTruncated={setTruncated}
      enabledToggle={enabledToggle}
      setEnabledToggle={setEnabledToggle}
    />
  );
};

const BillBillItem = ({
  isDocument,
  isEditable,
  showQuantity,
  showTotal,
  billItem,
  isHighlighted,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isEnabledBillItemDescriptionOnMobile,
  isEnabledTbdBillItems,
}) => {
  const isQuantityVisible = isEditable || showQuantity;
  const isTotalVisible = isEditable || showTotal;
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {responsive.mobile && !isDocument ? (
        <React.Fragment>
          <MobileBillItemText color={colors.gray.primary}>{billItem.name}</MobileBillItemText>
          <Space height={4} />
          <SplitRow>
            <Row>
              {isQuantityVisible && (
                <React.Fragment>
                  <QuantityColumn
                    billItem={billItem}
                    isHighlighted={isHighlighted}
                    isDocument={isDocument}
                    isEnabledTbdBillItems={isEnabledTbdBillItems}
                  />
                  <Space width={5} />
                  <Icon
                    source={Icon.Times}
                    color={colors.gray.primary}
                    size={10}
                    style={{alignSelf: 'center'}}
                  />
                  <Space width={5} />
                </React.Fragment>
              )}
              <PriceColumn
                billItem={billItem}
                isTotalVisible={isTotalVisible}
                isHighlighted={isHighlighted}
                isDocument={isDocument}
                isEnabledTbdBillItems={isEnabledTbdBillItems}
              />
            </Row>
            <Row>
              {isTotalVisible && (
                <TotalColumn
                  billItem={billItem}
                  isDocument={isDocument}
                  isEnabledTbdBillItems={isEnabledTbdBillItems}
                />
              )}
            </Row>
          </SplitRow>
          {isEnabledBillItemDescriptionOnMobile &&
            (setTruncated ? (
              <MobileDescription
                billItem={billItem}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
              />
            ) : (
              <MobileDescriptionWithState billItem={billItem} />
            ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Row>
            <BillItemName>{billItem.name}</BillItemName>
            <FlexSpaceHolder
              isQuantityVisible={isQuantityVisible}
              isTotalVisible={isTotalVisible}
            />
            {isQuantityVisible && (
              <QuantityColumn
                billItem={billItem}
                isHighlighted={isHighlighted}
                isDocument={isDocument}
                isEnabledTbdBillItems={isEnabledTbdBillItems}
              />
            )}
            <PriceColumn
              billItem={billItem}
              isTotalVisible={isTotalVisible}
              isHighlighted={isHighlighted}
              isDocument={isDocument}
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
            {isTotalVisible && (
              <TotalColumn
                billItem={billItem}
                isDocument={isDocument}
                isEnabledTbdBillItems={isEnabledTbdBillItems}
              />
            )}
          </Row>
          <Description description={billItem.description} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const NoBillItemsActionMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <QuestionIconCircle>
        <Icon source={Icon.Question} color={colors.white} size={7} />
      </QuestionIconCircle>
      <Space width={8} />
      <ListEmptyActionText>Press 'Edit' to start adding items to this bill</ListEmptyActionText>
    </ListEmptyMessageContainer>
  );
};

const NoBillItemsPlaceholderMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <CaptionText color={colors.gray.tertiary}>No items added to this bill yet.</CaptionText>
    </ListEmptyMessageContainer>
  );
};

const BillsListItemPreSubtotalBillItemsListV1 = ({
  bill,
  isDocument,
  isEditable,
  isHighlighted,
  showQuantity,
  showTotal,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isEnabledBillItemDescriptionOnMobile,
}) => {
  return (
    <React.Fragment>
      {bill.billItemsPreSubtotal.map((billItem, index) => {
        return (
          <React.Fragment key={`BILL_ITEM-${index}`}>
            {index > 0 && <Space height={8} />}
            <BillBillItem
              isDocument={isDocument}
              isEditable={isEditable}
              isHighlighted={isHighlighted}
              showQuantity={showQuantity}
              showTotal={showTotal}
              billItem={billItem}
              descriptionField={descriptionField}
              getTruncatedKey={getTruncatedKey}
              truncated={truncated}
              setTruncated={setTruncated}
              enabledToggle={enabledToggle}
              setEnabledToggle={setEnabledToggle}
              isEnabledBillItemDescriptionOnMobile={isEnabledBillItemDescriptionOnMobile}
              isEnabledTbdBillItems={bill.organization.features.isEnabledTbdBillItems}
            />
          </React.Fragment>
        );
      })}
      {_.isEmpty(bill.billItemsPreSubtotal) && isEditable && <NoBillItemsActionMessage />}
      {_.isEmpty(bill.billItemsPreSubtotal) && !isEditable && <NoBillItemsPlaceholderMessage />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemPreSubtotalBillItemsListV1.fragment = gql`
  ${BillItem.getDisplayPrice.fragment}
  ${BillItem.getEstimateQuantity.fragment}
  ${BillItem.getEstimateTotal.fragment}

  fragment BillsListItemPreSubtotalBillItemsListV1 on Bill {
    id
    billItemsPreSubtotal {
      id
      name
      description
      isCredit
      ...BillItem_getDisplayPrice
      ...BillItem_getEstimateQuantity
      ...BillItem_getEstimateTotal
    }
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

export default BillsListItemPreSubtotalBillItemsListV1;
