// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Switch from '@shared/design/components/Switch';
import Table from '@shared/design/components/Table';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useUpdateProjectTypeIndexMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeIndexMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import EditProjectTypeModal from 'modules/Organization/Settings/ProjectTypes/components/EditProjectTypeModal';
import RemoveProjectTypeModal from 'modules/Organization/Settings/ProjectTypes/components/RemoveProjectTypeModal';

const ProjectTypesTableContainer = Styled.View`
  width: ${({isReordering}) => (isReordering ? `1300px` : `1250px`)};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
;`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link2}
`;

const ColorDot = Styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({color}) => color};
`;

const CellColumn = Styled.View`
  flex-direction: column;
`;

const NameText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const EditProjectTypeButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const TableControlsRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ReorderButtonsColumnContainer = Styled.View`
  width: 16px;
  height: 36px;
  justify-content: center;
`;

const ReorderButtonIconCircle = Styled.Touchable`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${(props) =>
    props.color ? props.color : props.disabled ? colors.gray.border : colors.blue.interactive};
  justify-content: center;
  align-items: center;
`;

const ProjectTypeBillTypes = ({projectType, navigator}) => {
  const {billTypes} = projectType;
  if (_.isEmpty(billTypes)) {
    return <Text style={{color: colors.gray.tertiary}}>None selected</Text>;
  }

  return (
    <Text>
      {billTypes.map((billType, index) => (
        <React.Fragment key={billType.id}>
          <LinkButton
            onPress={() =>
              navigator.push(
                `/settings/billing/billing-libraries/${projectType.billingLibrary.uuid}/bill-templates/${billType.uuid}`,
              )
            }
          >
            <LinkText>{billType.name}</LinkText>
          </LinkButton>
          {index !== billTypes.length - 1 && <Text>, </Text>}
        </React.Fragment>
      ))}
    </Text>
  );
};

const getProjectTypesColumnDefinitions = ({
  organization,
  refetch,
  itemsCount,
  isReordering,
  isStorageSettingsPage,
  navigator,
}) => [
  {
    width: 50,
    headerContent: () => {},
    isHidden: !isReordering,
    cellContent: ({item: projectType, rowIndex: index}) => {
      const isFirstItem = index === 0;
      const isLastItem = index === itemsCount - 1;
      return (
        <ReorderButtonsColumn
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          projectType={projectType}
          index={index}
          refetch={refetch}
        />
      );
    },
  },
  {
    width: 50,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: projectType}) => {
      return <ColorDot color={projectType.color} />;
    },
  },
  {
    width: 250,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    cellContent: ({item: projectType}) => {
      return (
        <Text>
          <LinkButton
            onPress={() => navigator.push(`/settings/project-types/${projectType.uuid}/job-types`)}
          >
            <LinkText>{projectType.name}</LinkText>
          </LinkButton>
        </Text>
      );
    },
  },
  {
    width: 250,
    headerContent: () => {
      return <TableHeaderText>Billing Library</TableHeaderText>;
    },
    cellContent: ({item: projectType}) => {
      return (
        <Text>
          <LinkButton
            onPress={() =>
              navigator.push(
                `/settings/billing/billing-libraries/${projectType.billingLibrary.uuid}/items/fees`,
              )
            }
          >
            <LinkText>{projectType.billingLibrary.name}</LinkText>
          </LinkButton>
        </Text>
      );
    },
  },
  {
    width: 300,
    isHidden: isStorageSettingsPage,
    headerContent: () => {
      return <TableHeaderText>Default Bill Templates</TableHeaderText>;
    },
    cellContent: ({item: projectType}) => {
      return <ProjectTypeBillTypes projectType={projectType} navigator={navigator} />;
    },
  },
  {
    width: 100,
    isHidden: isStorageSettingsPage,
    headerContent: () => {
      return <TableHeaderText>Job Types</TableHeaderText>;
    },
    cellContent: ({item: projectType}) => {
      return (
        <Text style={_.isEmpty(projectType.jobTypes) ? {color: colors.gray.tertiary} : {}}>
          {projectType.jobTypes.length}
        </Text>
      );
    },
  },
  {
    width: 200,
    headerContent: () => {
      return <TableHeaderText>Last Updated</TableHeaderText>;
    },
    cellContent: ({item: projectType}) => {
      return (
        <CellColumn>
          <Text>{Datetime.convertToDisplayDatetime(projectType.updatedAt)}</Text>
          {projectType.updatedBy ? <NameText>By {projectType.updatedBy.fullName}</NameText> : null}
        </CellColumn>
      );
    },
  },
  {
    width: 50,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    cellContent: ({item: projectType}) => {
      return (
        <ProjectTypeActions
          organization={organization}
          projectType={projectType}
          refetch={refetch}
          isStorageSettingsPage={isStorageSettingsPage}
        />
      );
    },
  },
];

const ReorderButtonsColumn = ({projectType, index, refetch, isFirstItem, isLastItem}) => {
  const projectTypeForm = ProjectTypeForm.toForm({
    projectTypeId: projectType.id,
    organizationId: projectType.organizationId,
    ...projectType,
  });

  const {form, handleSubmit} = useUpdateProjectTypeIndexMutation({
    projectTypeForm,
    onSuccess: () => {
      refetch();
    },
    onError: () => {},
  });

  const handleMoveItemUp = () => {
    form.setFieldValue('projectTypeForm.index', index - 1);
    setTimeout(() => handleSubmit(), 0);
  };
  const handleMoveItemDown = () => {
    form.setFieldValue('projectTypeForm.index', index + 1);
    setTimeout(() => handleSubmit(), 0);
  };
  return (
    <ReorderButtonsColumnContainer>
      <ReorderButtonIconCircle
        activeOpacity={0.8}
        disabled={isFirstItem}
        onPress={handleMoveItemUp}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.interactive}
        />
      </ReorderButtonIconCircle>
      <Space height={2} />
      <ReorderButtonIconCircle
        activeOpacity={0.8}
        disabled={isLastItem}
        onPress={handleMoveItemDown}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.interactive}
        />
      </ReorderButtonIconCircle>
    </ReorderButtonsColumnContainer>
  );
};

const ProjectTypeActionsMenu = ({popover, removeProjectTypeModal}) => {
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          removeProjectTypeModal.handleOpen();
          popover.handleClose();
        }}
      >
        Remove
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const ProjectTypeActions = ({organization, projectType, refetch, isStorageSettingsPage}) => {
  const editProjectTypeModal = useModal({name: 'Edit Project Type Modal'});
  const projectTypeActionsPopover = usePopover();
  const removeProjectTypeModal = useModal({name: 'Remove Project Type Modal'});

  return (
    <React.Fragment>
      <EditProjectTypeButton onPress={editProjectTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditProjectTypeButton>
      <Space width={12} />
      <EditProjectTypeModal
        key={editProjectTypeModal.isOpen}
        isOpen={editProjectTypeModal.isOpen}
        handleClose={editProjectTypeModal.handleClose}
        projectTypeId={projectType.id}
        isStorageSettingsPage={isStorageSettingsPage}
        refetch={refetch}
      />
      {projectType.isDeletable && organization.isOwnerOfSettings && (
        <Popover.Content innerRef={projectTypeActionsPopover.ref}>
          <MoreActionsButton onPress={projectTypeActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      )}
      <ProjectTypeActionsMenu
        popover={projectTypeActionsPopover}
        removeProjectTypeModal={removeProjectTypeModal}
      />
      <RemoveProjectTypeModal
        projectType={projectType}
        refetch={refetch}
        removeProjectTypeModal={removeProjectTypeModal}
      />
    </React.Fragment>
  );
};

const ProjectTypesTable = ({
  organization,
  refetch,
  setIsReordering,
  isReordering,
  isStorageSettingsPage,
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <ProjectTypesTableContainer isReordering={isReordering}>
      {organization.isOwnerOfSettings && (
        <TableControlsRow>
          <Switch isOn={isReordering} onChange={setIsReordering} labelLeft='Reorder?' />
        </TableControlsRow>
      )}
      <Space height={8} />
      <Table
        columnDefinitions={getProjectTypesColumnDefinitions({
          organization,
          refetch,
          itemsCount: organization.projectTypesForCategory.length,
          isReordering,
          isStorageSettingsPage,
          navigator,
        })}
        emptyStateText='No project types to display'
        items={organization.projectTypesForCategory}
        itemKey={'id'}
      />
    </ProjectTypesTableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypesTable.fragment = gql`
  ${RemoveProjectTypeModal.fragment}
  fragment ProjectTypesTable on Organization {
    id
    isOwnerOfSettings
    projectTypesForCategory(category: $category) {
      id
      uuid
      index
      color
      name
      category
      kind
      isDeletable
      updatedAt
      updatedBy {
        id
        fullName
      }
      billTypes {
        id
        uuid
        name
      }
      billingLibrary {
        id
        uuid
        name
      }
      jobTypes {
        id
      }
      ...RemoveProjectTypeModal
    }
  }
`;

export default ProjectTypesTable;
