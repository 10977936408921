// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Percent, withFragment} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemTypeMoverPositionRateForm from '@shared/modules/Billing/forms/BillItemTypeMoverPositionRateForm';

const getUnitFromKind = (kind) => {
  if (kind === BillItemTypeKind.PERCENTAGE) {
    return BillItemUnit.PERCENT;
  }
  return BillItemUnit.DOLLAR;
};

const getKindFromUnit = (unit) => {
  if (unit === BillItemUnit.PERCENT) {
    return BillItemTypeKind.PERCENTAGE;
  }
  return BillItemTypeKind.AMOUNT;
};

const hasAmountFormula = (billItemTypeForm) => {
  return !!billItemTypeForm.amountFormulaId;
};

const hasNameFormula = (billItemTypeForm) => {
  return !!billItemTypeForm.nameFormulaId;
};

const handlePositionRatesToggled = ({form, field}) => {
  form.setFieldValue(`${field}.externalInvoiceItemId`, null);
  form.setFieldValue(`${field}.amount`, '');
  form.setFieldValue(`${field}.billItemTypeMoverPositionRateForms`, []);
  form.setFieldValue(`${field}.moverPositionIds`, []);
  form.setFieldValue(`${field}.amountFormulaId`, null);
  form.setFieldValue(`${field}.maxQuantityFormulaId`, null);
  form.setFieldValue(`${field}.minQuantityFormulaId`, null);
  form.setFieldValue(`${field}.nameFormulaId`, null);
};

const _new = ({billingLibraryId, organizationId, category, userId, isEnabledTbdBillItems}) => ({
  billItemTypeId: null,
  billingLibraryId,
  organizationId,
  name: '',
  amount: '',
  percentage: '',
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  description: '',
  category,
  amountFormulaId: null,
  maxQuantityFormulaId: null,
  minQuantityFormulaId: null,
  nameFormulaId: null,
  identifier: null,
  isDeleted: false,
  maxQuantity: null,
  minQuantity: null,
  unit: BillItemUnit.DOLLAR,
  createdById: userId,
  updatedById: userId,
  billItemTypeMoverPositionRateForms: [],
  isTaxable: category === BillItemTypeCategory.SUPPLIES,
  externalInvoiceItemId: null,

  // private
  isEnabledPerPositionRates: false,
  moverPositionIds: [],
  isEnabledTbdBillItems,
});

const edit = withFragment(
  (billItemType, {userId}) => {
    const {isEnabledTbdBillItems} = billItemType.organization.features;
    return {
      billItemTypeId: billItemType.id,
      billingLibraryId: billItemType.billingLibraryId,
      organizationId: billItemType.organizationId,
      name: billItemType.name,
      amount: billItemType.amount,
      percentage: billItemType.percentage,
      kind: billItemType.kind,
      billStage: billItemType.billStage,
      description: billItemType.description,
      category: billItemType.category,
      amountFormulaId: billItemType.amountFormulaId,
      maxQuantityFormulaId: billItemType.maxQuantityFormulaId,
      minQuantityFormulaId: billItemType.minQuantityFormulaId,
      nameFormulaId: billItemType.nameFormulaId,
      identifier: billItemType.identifier,
      isDeleted: billItemType.isDeleted,
      maxQuantity: isEnabledTbdBillItems
        ? billItemType.maxQuantity
        : billItemType.maxQuantity || '',
      minQuantity: isEnabledTbdBillItems
        ? billItemType.minQuantity
        : billItemType.minQuantity || '',
      unit: billItemType.unit || getUnitFromKind(billItemType.kind),
      updatedById: userId,
      createdById: billItemType.createdById,
      billItemTypeMoverPositionRateForms: billItemType.childBillItemTypes.map(
        (childBillItemType) => {
          return BillItemTypeMoverPositionRateForm.edit(childBillItemType);
        },
      ),
      isTaxable: billItemType.isTaxable,
      externalInvoiceItemId: billItemType.externalInvoiceItem?.id,

      // private
      isEnabledPerPositionRates: billItemType.childBillItemTypes.length > 0,
      moverPositionIds: billItemType.childBillItemTypes.map(
        (childBillItemType) => childBillItemType.moverPositionId,
      ),
      isEnabledTbdBillItems,
    };
  },
  gql`
    ${BillItemTypeMoverPositionRateForm.edit.fragment}
    fragment BillItemTypeForm_edit on BillItemType {
      id
      createdById
      billingLibraryId
      organizationId
      name
      amount
      percentage
      kind
      billStage
      description
      category
      amountFormulaId
      maxQuantityFormulaId
      minQuantityFormulaId
      nameFormulaId
      identifier
      isDeleted
      maxQuantity
      minQuantity
      unit
      externalInvoiceItem {
        id
      }
      childBillItemTypes {
        id
        moverPositionId
        ...BillItemTypeMoverPositionRateForm_edit
      }
      isTaxable
      organization {
        id
        features {
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
      }
    }
  `,
);

const toForm = ({
  billItemTypeId,
  billingLibraryId,
  organizationId,
  name,
  amount,
  percentage,
  billStage,
  description,
  category,
  amountFormulaId,
  maxQuantityFormulaId,
  minQuantityFormulaId,
  nameFormulaId,
  identifier,
  isDeleted,
  maxQuantity,
  minQuantity,
  unit,
  createdById,
  updatedById,
  billItemTypeMoverPositionRateForms,
  isEnabledPerPositionRates,
  moverPositionIds,
  isTaxable,
  externalInvoiceItemId,
  isEnabledTbdBillItems,
}) => {
  return {
    billItemTypeId,
    billingLibraryId,
    organizationId,
    name,
    amount: Currency.toForm(amount),
    percentage: Percent.toForm(percentage),
    kind: getKindFromUnit(unit),
    billStage,
    description,
    category,
    amountFormulaId,
    maxQuantityFormulaId,
    minQuantityFormulaId,
    nameFormulaId,
    identifier,
    isDeleted,
    maxQuantity: _.toString(maxQuantity),
    minQuantity: _.toString(minQuantity),
    unit,
    createdById,
    updatedById,
    billItemTypeMoverPositionRateForms: billItemTypeMoverPositionRateForms.map(
      (billItemTypeMoverPositionRateForm) => {
        return BillItemTypeMoverPositionRateForm.toForm(billItemTypeMoverPositionRateForm);
      },
    ),
    isTaxable,
    externalInvoiceItemId,

    // private
    isEnabledPerPositionRates,
    moverPositionIds,
    isEnabledTbdBillItems,
  };
};

const getAmountToMutation = ({amount, amountFormulaId, kind, isEnabledTbdBillItems}) => {
  if (isEnabledTbdBillItems) {
    if (amountFormulaId || kind === BillItemTypeKind.PERCENTAGE) {
      return null;
    }
    return Currency.toMutation(amount, isEnabledTbdBillItems);
  }
  return amountFormulaId
    ? 0
    : kind === BillItemTypeKind.AMOUNT
      ? Currency.toMutation(amount)
      : null;
};

const toMutation = ({
  billItemTypeId,
  billingLibraryId,
  organizationId,
  name,
  amount,
  percentage,
  billStage,
  description,
  category,
  amountFormulaId,
  maxQuantityFormulaId,
  minQuantityFormulaId,
  nameFormulaId,
  identifier,
  isDeleted,
  maxQuantity,
  minQuantity,
  unit,
  createdById,
  updatedById,
  billItemTypeMoverPositionRateForms,
  isTaxable,
  externalInvoiceItemId,
  isEnabledTbdBillItems,
}) => {
  const kind = getKindFromUnit(unit);
  return {
    billItemTypeId,
    billingLibraryId,
    organizationId,
    name,
    amount: getAmountToMutation({amount, amountFormulaId, kind, isEnabledTbdBillItems}),
    percentage:
      kind === BillItemTypeKind.PERCENTAGE
        ? Percent.toMutation(percentage, isEnabledTbdBillItems)
        : null,
    kind,
    billStage,
    description,
    category,
    amountFormulaId,
    maxQuantityFormulaId,
    minQuantityFormulaId,
    nameFormulaId,
    identifier,
    isDeleted,
    maxQuantity: isEnabledTbdBillItems && maxQuantity === '' ? null : _.toNumber(maxQuantity),
    minQuantity: isEnabledTbdBillItems && minQuantity === '' ? null : _.toNumber(minQuantity),
    unit: unit === BillItemUnit.HOUR ? unit : null,
    createdById,
    updatedById,
    billItemTypeMoverPositionRateForms: billItemTypeMoverPositionRateForms.map(
      (billItemTypeMoverPositionRateForm) => {
        return BillItemTypeMoverPositionRateForm.toMutation(billItemTypeMoverPositionRateForm);
      },
    ),
    isTaxable,
    externalInvoiceItemId,
  };
};

const BillingLibraryForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  hasAmountFormula,
  hasNameFormula,
  handlePositionRatesToggled,
};

export default BillingLibraryForm;
