// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MultiDropdownInput, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const MultiBranchDropdownFilter = ({index, formName, form, organization}) => {
  const organizations = _.get(organization, 'company.organizations', []);
  const {params} = useNavigationDOM();

  return (
    <MultiBranchOrganizationField
      label={'Branches'}
      width={'100%'}
      index={index}
      isPortaled={false}
      value={params.slugs || []}
      organizations={organizations}
      disableAllOption
      onChangeValue={(newSlugs) => {
        form.setFieldValue(`${formName}.slugs`, newSlugs);
      }}
    />
  );
};

const SalespeopleMultiselect = ({index, formName, form, organization}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${formName}.salespersonIds`}
      label={'Salesperson'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: Organization.getSalespersonOptions(organization).map((user) => ({
          label: user.label,
          value: _.toString(user.value), // Keeps filter option selected when page is refreshed
          description: user.description,
        })),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
        showDescriptionInOption: true,
      }}
    />
  );
};

const ClientFilterPopoverContent = ({organization, form, isRestricted, viewerId}) => {
  const formName = 'filteredClientsForm';

  const shouldSeeBranchFilter =
    organization.isPrimary && organization.features.isEnabledClientMultibranchMigration;

  return (
    <ResponsivePopover.StaticContainer width={320} maxHeight={480}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filter</Header>
        <Space height={8} />
        {!isRestricted && (
          <React.Fragment>
            <SalespeopleMultiselect
              organization={organization}
              form={form}
              formName={formName}
              index={0}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        {shouldSeeBranchFilter && (
          <React.Fragment>
            <MultiBranchDropdownFilter
              organization={organization}
              form={form}
              formName={formName}
              index={1}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        <SecondaryButton
          text={'Clear Filters'}
          onPress={() => {
            form.setFieldValue(
              'filteredClientsForm.salespersonIds',
              isRestricted ? [viewerId] : null,
            );
            form.setFieldValue(
              'filteredClientsForm.slugs',
              organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
            );
          }}
        />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const ClientsFilterPopover = ({popover, organization, form, isRestricted, viewerId}) => {
  return (
    <React.Fragment>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <ClientFilterPopoverContent
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          viewerId={viewerId}
        />
      </Popover>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientsFilterPopover.fragment = gql`
  ${Organization.getSalespersonOptions.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment ClientsFilterPopover on Organization {
    id
    isPrimary
    features {
      isEnabledClientMultibranchMigration: isEnabled(feature: "CLIENT_MULTIBRANCH_MIGRATION")
    }
    ...Organization_getSalespersonOptions
    ...MultiBranchOrganizationField
  }
`;

export default ClientsFilterPopover;
