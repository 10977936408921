// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Switch from '@shared/design/components/Switch';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const LoadingField = ({isLoading, label, children}) => {
  const responsive = useResponsive();
  return isLoading ? (
    <Container>
      <FieldInput.LabelText isResponsive isRequired>
        {label}
      </FieldInput.LabelText>
      <Space height={responsive.desktop ? 6 : 8} />
      <SkeletonLoader isFullWidth height={responsive.desktop ? 36 : 48} />
    </Container>
  ) : (
    children
  );
};

const TaxableCheckbox = ({form, field}) => {
  return (
    <Checkbox
      isChecked={_.get(form.values, `${field}.isTaxable`)}
      handleToggle={() =>
        form.setFieldValue(`${field}.isTaxable`, !_.get(form.values, `${field}.isTaxable`))
      }
      label={'Taxable'}
      isResponsive
    />
  );
};

const QuantityRangeSwitch = ({
  form,
  field,
  isMaxQuantityFieldVisible,
  setIsMaxQuantityFieldVisible,
}) => {
  return (
    <Switch
      isOn={isMaxQuantityFieldVisible}
      onChange={(newValue) => {
        setIsMaxQuantityFieldVisible(newValue);
        if (!newValue) {
          form.setFieldValue(`${field}.maxQuantity`, null);
        }
      }}
      color={colors.blue.interactive}
      labelRight={'Set range'}
      isResponsive
      disabled={!!_.get(form.values, `${field}.maxQuantityFormulaId`)}
    />
  );
};

const EditBillItemFields = ({previewBillItemMutation, form, field}) => {
  const billItemForm = _.get(form.values, field);
  // Only show max quantity field if max quantity is set and different from min quantity
  const [isMaxQuantityFieldVisible, setIsMaxQuantityFieldVisible] = useState(
    !!billItemForm.maxQuantity && billItemForm.maxQuantity !== billItemForm.minQuantity,
  );
  const {hasNameFormula, hasQuantityFormula, hasAmountFormula} =
    BillItemForm.getHasFormulas(billItemForm);
  return (
    <React.Fragment>
      <LoadingField isLoading={previewBillItemMutation.submitting && hasNameFormula} label={'Name'}>
        <FieldInput
          {...form}
          label={'Name'}
          name={`${field}.name`}
          isRequired
          isResponsive
          input={{
            placeholder: 'Enter name',
            disabled: hasNameFormula,
          }}
        />
      </LoadingField>
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Description'}
        name={`${field}.description`}
        isResponsive
        input={{
          placeholder: 'Enter description',
        }}
      />
      <Space height={16} />
      <LoadingField
        isLoading={previewBillItemMutation.submitting && hasQuantityFormula}
        label={'Quantity'}
      >
        <Row style={{flex: 1}}>
          <FieldInput
            {...form}
            label={'Quantity'}
            name={`${field}.minQuantity`}
            isResponsive
            handleBlur={() => BillItemForm.handleQuantityOnBlur({form, field, billItemForm})}
            input={{
              placeholder: 'Enter quantity',
              disabled: hasQuantityFormula,
            }}
            style={{flex: 1}}
            action={
              !isMaxQuantityFieldVisible && (
                <QuantityRangeSwitch
                  form={form}
                  field={field}
                  isMaxQuantityFieldVisible={isMaxQuantityFieldVisible}
                  setIsMaxQuantityFieldVisible={setIsMaxQuantityFieldVisible}
                />
              )
            }
          />
          {isMaxQuantityFieldVisible && (
            <Row>
              <Space width={16} />
              <FieldInput
                {...form}
                label={' '}
                isResponsive
                name={`${field}.maxQuantity`}
                handleBlur={() => BillItemForm.handleQuantityOnBlur({form, field, billItemForm})}
                input={{
                  placeholder: 'Enter max quantity',
                  disabled: hasQuantityFormula,
                }}
                style={{flex: 1}}
                action={
                  isMaxQuantityFieldVisible && (
                    <QuantityRangeSwitch
                      form={form}
                      field={field}
                      isMaxQuantityFieldVisible={isMaxQuantityFieldVisible}
                      setIsMaxQuantityFieldVisible={setIsMaxQuantityFieldVisible}
                    />
                  )
                }
              />
            </Row>
          )}
        </Row>
      </LoadingField>
      <Space height={16} />
      <LoadingField
        isLoading={previewBillItemMutation.submitting && hasAmountFormula}
        label={'Amount'}
      >
        <FieldInput
          {...form}
          label={'Price ($)'}
          name={`${field}.amount`}
          isResponsive
          component={CurrencyInput}
          input={{
            placeholder: 'Enter price',
            disabled: hasAmountFormula,
            component: TextInput,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
          }}
          action={<TaxableCheckbox form={form} field={field} />}
        />
      </LoadingField>
    </React.Fragment>
  );
};

export default EditBillItemFields;
