// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, MultiDropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization, ReferralSource} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';

const Row = Styled.View`
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const OfficeNotesRow = ({form, field, index}) => {
  return (
    <FieldInput.Memoized
      {...form}
      name={`${field}.officeNotes`}
      label={'Office Notes'}
      index={index}
      input={{
        placeholder: 'Enter any notes for the office',
        style: {minHeight: 76, paddingTop: 8},
        multiline: true,
      }}
    />
  );
};

const WarehouseNotesRow = ({form, field, index}) => {
  return (
    <FieldInput.Memoized
      {...form}
      name={`${field}.warehouseNotes`}
      label={'Warehouse Notes'}
      index={index}
      input={{
        placeholder: 'Enter any notes for the warehouse',
        style: {minHeight: 76, paddingTop: 8},
        multiline: true,
      }}
    />
  );
};

const StaffInformationRow = ({form, field, index, organization}) => {
  const responsive = useResponsive();
  const isCoordinatorRequired =
    organization.features.isEnabledProjectCoordinatorRequired && _.has(form.values, 'projectForm');
  const allowEmptyBookedBy = organization.features.isEnabledAllowEmptyBookedBy;

  return (
    <Row index={index} {...responsive}>
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.bookedById`}
        label={'Salesperson'}
        isClearable={allowEmptyBookedBy}
        input={{
          options: Organization.getSalespersonOptions(organization),
          placeholder: 'Select salesperson',
          isSearchable: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          menuPlacement: 'top',
          components: organization.features.isEnabledShowSalespersonsFromAllOrganizations
            ? {
                Option: DropdownInput.OptionWithDescription,
              }
            : {},
        }}
        style={{flex: 1}}
      />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.coordinatedById`}
        label={'Coordinator'}
        isRequired={isCoordinatorRequired}
        input={{
          options: Organization.getSalespersonOptions(organization),
          placeholder: 'Select coordinator',
          isSearchable: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          menuPlacement: 'top',
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const AdditionalSalespersonsRow = ({form, field, index, organization}) => {
  const {isEnabledProjectManagersField, isEnabledShowSalespersonsFromAllOrganizations} =
    organization.features;
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      component={MultiDropdownInput}
      name={`${field}.additionalSalespersonIds`}
      label={isEnabledProjectManagersField ? 'Project Managers' : 'Additional Salespersons'}
      input={{
        options: Organization.getSalespersonOptions(organization),
        placeholder: `Select ${
          isEnabledProjectManagersField ? 'project managers' : 'additional salespersons'
        }`,
        isSearchable: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        menuPlacement: 'top',
        components: isEnabledShowSalespersonsFromAllOrganizations
          ? {
              Option: DropdownInput.OptionWithDescription,
            }
          : {},
      }}
    />
  );
};

const ReferralInformationRow = ({form, field, index, organization}) => {
  const responsive = useResponsive();
  const {referralSources} = organization.settings;
  return (
    <Row index={index} {...responsive}>
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.referralSource`}
        label={'How did you hear about us?'}
        input={{
          options: ReferralSource.getDropdownOptions({referralSources}),
          placeholder: 'Select one',
          isSearchable: true,
          required:
            organization.features.isEnabledProjectReferralSourceRequired &&
            !_.get(form.values, `${field}.referralSource`),
          setFieldValue: (name, value) => {
            const previousValue = _.get(form.values, `${field}.referralSource`);
            // First update the referralSource.
            form.setFieldValue(name, value);

            // When the referralSource is updated, we clear the referralDetails.
            if (previousValue !== value) {
              form.setFieldValue(`${field}.referralDetails`, '');
            }

            // Update on job level and project level if it has a jobForm, iterate through each
            const jobForms = `${field}.jobForms`;
            if (_.get(form.values, jobForms)) {
              _.get(form.values, jobForms).forEach((_jobForm, index) => {
                // Update referralSource
                form.setFieldValue(`${jobForms}.${index}.referralSource`, value);
                // When the referralSource is updated, we clear the referralDetails.
                if (previousValue !== value) {
                  form.setFieldValue(`${jobForms}.${index}.referralDetails`, '');
                }
              });
            }
          },
          style: {
            flex: 1,
          },
          menuPlacement: 'top',
        }}
        style={{flex: 1}}
      />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        name={`${field}.referralDetails`}
        label={'Referral Details'}
        input={{
          placeholder: 'Please specify',
          onChangeText: (text) => {
            form.setFieldValue(`${field}.referralDetails`, text);
            // Update on job level and project level if it has a jobForm, iterate through each
            const jobForms = `${field}.jobForms`;
            if (_.get(form.values, jobForms)) {
              _.get(form.values, jobForms).forEach((_jobForm, index) => {
                // Update referralDetails
                form.setFieldValue(`${jobForms}.${index}.referralDetails`, text);
              });
            }
          },
          style: {
            flex: 1,
          },
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const ProjectInternalFields = ({
  form,
  field,
  organization,
  hasWarehouseNotes,
  hasStaffInformation,
}) => {
  // Here we reverse the indexes because the dropdowns appear above the input.
  // They appear above the input because this block is at the bottom of the modal.
  return (
    <React.Fragment>
      <Modal.BlockHeader>Internal Information</Modal.BlockHeader>
      <FieldSpace />
      <OfficeNotesRow form={form} field={field} index={4} />
      {hasWarehouseNotes && (
        <React.Fragment>
          <FieldSpace />
          <WarehouseNotesRow form={form} field={field} index={3} />
        </React.Fragment>
      )}
      {hasStaffInformation && (
        <React.Fragment>
          <FieldSpace />
          <StaffInformationRow form={form} field={field} index={2} organization={organization} />
          {organization.features.isEnabledAdditionalSalespersonIdsInput && (
            <React.Fragment>
              <FieldSpace />
              <AdditionalSalespersonsRow form={form} field={field} organization={organization} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <FieldSpace />
      <ReferralInformationRow form={form} field={field} index={0} organization={organization} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectInternalFields.fragment = gql`
  ${Organization.getSalespersonOptions.fragment}
  ${ReferralSource.getDropdownOptions.fragment}

  fragment ProjectInternalFields on Organization {
    id
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    features {
      isEnabledAdditionalSalespersonIdsInput: isEnabled(feature: "ADDITIONAL_SALESPERSON_IDS_INPUT")
      isEnabledProjectCoordinatorRequired: isEnabled(feature: "PROJECT_COORDINATOR_REQUIRED")
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
      isEnabledProjectReferralSourceRequired: isEnabled(feature: "PROJECT_REFERRAL_SOURCE_REQUIRED")
      isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
    }
    ...Organization_getSalespersonOptions
  }
`;

export default ProjectInternalFields;
