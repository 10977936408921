// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Popover} from '@supermove/components';
import {FilteredProjectsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  useDebouncedCallback,
  usePopover,
  useState,
  useResponsive,
} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import StorageProjectStatus from '@shared/modules/Project/enums/StorageProjectStatus';
import useManagerRestriction from 'modules/App/hooks/useManagerRestriction';
import CreateProjectSuccessModal from 'modules/Project/components/CreateProjectSuccessModal';
import CreateStorageProjectModal from 'modules/Storage/components/CreateStorageProjectModal';
import ListStorageProjectsPageFiltersPopover from 'modules/Storage/components/ListStorageProjectsPageFiltersPopover';

const HeaderContainer = Styled.View`
`;

const SearchFilterContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: ${({responsive}) => (responsive.desktop ? 'row' : 'column-reverse')};
  width: 100%;
  align-items: ${({responsive}) => (responsive.desktop ? 'center' : 'flex-start')};
  justify-content: space-between;
`;

const TabsContainer = Styled.View`
`;

const getTabDefinitions = ({storageActiveCount, expiredCount, completedCount, cancelledCount}) => [
  {
    key: _.lowerCase(StorageProjectStatus.ACTIVE),
    label: 'Active',
    count: storageActiveCount,
    status: `${_.lowerCase(StorageProjectStatus.ACTIVE)}`,
  },
  {
    key: _.lowerCase(StorageProjectStatus.EXPIRED),
    label: 'Expired',
    count: expiredCount,
    status: `${_.lowerCase(StorageProjectStatus.EXPIRED)}`,
  },
  {
    key: _.lowerCase(StorageProjectStatus.COMPLETED),
    label: 'Completed',
    count: completedCount,
    status: `${_.lowerCase(StorageProjectStatus.COMPLETED)}`,
  },
  {
    key: _.lowerCase(StorageProjectStatus.CANCELLED),
    label: 'Cancelled',
    count: cancelledCount,
    status: `${_.lowerCase(StorageProjectStatus.CANCELLED)}`,
  },
];

const TabHeader = ({activeTabKey, filteredProjectCountsByStatus, form}) => {
  const tabDefinitions = getTabDefinitions(filteredProjectCountsByStatus);
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.status === activeTabKey,
  );

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({status}) => {
          form.setFieldValue('filteredProjectsForm.status', status);
        }}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const ProjectsFilterButton = ({form, organization, responsive}) => {
  const {isRestricted, viewerId} = useManagerRestriction({
    isBypassed: organization.features.isEnabledSalespersonViewAllProjects,
    handleRestriction: (viewerId) => {
      form.setFieldValue(`filteredProjectsForm.salespersonIds`, [viewerId]);
    },
  });

  const listStorageProjectsPageFiltersPopover = usePopover({
    name: 'List Projects Page Filters Popover',
  });
  // Determine number of filters applied
  const numberOfFiltersApplied = FilteredProjectsForm.getNumberOfFiltersApplied({
    form,
    isPrimary: organization.isPrimary,
    isRestricted,
  });
  const baseLabel = responsive.desktop ? 'Filter' : '';
  const filterLabel =
    numberOfFiltersApplied === 0
      ? baseLabel
      : `${baseLabel}${responsive.desktop ? ' ' : ''}(${numberOfFiltersApplied})`;

  return (
    <React.Fragment>
      <Popover.Content innerRef={listStorageProjectsPageFiltersPopover.ref}>
        {responsive.desktop ? (
          <SecondaryButton
            text={filterLabel}
            iconLeft={Icon.Filter}
            onPress={listStorageProjectsPageFiltersPopover.handleToggle}
          />
        ) : (
          <SecondaryButton
            iconLeft={Icon.Filter}
            onPress={listStorageProjectsPageFiltersPopover.handleToggle}
          />
        )}
      </Popover.Content>
      <ListStorageProjectsPageFiltersPopover
        organization={organization}
        form={form}
        popover={listStorageProjectsPageFiltersPopover}
        isRestricted={isRestricted}
        viewerId={viewerId}
      />
    </React.Fragment>
  );
};

const ListStorageProjectsPageFilters = ({
  form,
  organization,
  filteredProjectCountsByStatus,
  refetch,
}) => {
  const responsive = useResponsive();
  const [newUuid, setNewUuid] = useState(null);
  const createStorageProjectModal = useModal({
    name: 'Create Storage Project Modal',
  });
  const createStorageProjectSuccessModal = useModal({
    name: 'Create Storage Project Success Modal',
  });
  const {params, navigator} = useNavigationDOM();

  const handleChangeSearch = useDebouncedCallback(
    (input) => form.setFieldValue('filteredProjectsForm.searchQuery', input),
    250,
    {leading: true},
  );

  const handleOpenCreateStorageProjectModal = () => {
    // Set uuid of newly created storage project to null after clicking on Create Storage Project
    setNewUuid(null);
    createStorageProjectModal.handleOpen();
  };

  return (
    <HeaderContainer>
      <FiltersContainers responsive={responsive}>
        <SearchFilterContainer style={responsive.desktop ? {} : {width: '100%'}}>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder={
              responsive.mobile ? 'Search' : 'Search by customer name or project details'
            }
            style={responsive.desktop ? {width: 348} : {flex: 1, minWidth: 50}}
            containerStyle={{flex: 1}}
            defaultValue={_.get(form.values, 'filteredProjectsForm.searchQuery')}
            isClearable
          />
          <Space width={12} />
          <ProjectsFilterButton organization={organization} form={form} responsive={responsive} />
        </SearchFilterContainer>
        <Space style={{flex: 1, minWidth: 8, minHeight: 12}} />
        <Button
          text={'Create Storage Project'}
          iconLeft={Icon.Plus}
          onPress={handleOpenCreateStorageProjectModal}
        />
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        activeTabKey={params.status}
        filteredProjectCountsByStatus={filteredProjectCountsByStatus}
        form={form}
      />
      <CreateStorageProjectModal
        key={createStorageProjectModal.key}
        isOpen={createStorageProjectModal.isOpen}
        handleClose={createStorageProjectModal.handleClose}
        refetch={refetch}
        organization={organization}
        createProjectSuccessModal={createStorageProjectSuccessModal}
        setNewUuid={setNewUuid}
      />
      <CreateProjectSuccessModal
        key={newUuid}
        projectUuid={newUuid}
        isOpen={createStorageProjectSuccessModal.isOpen}
        handleClose={createStorageProjectSuccessModal.handleClose}
        title={'Storage project created!'}
        handleViewProject={() => navigator.push(`/storage/projects/${newUuid}`)}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListStorageProjectsPageFilters.fragment = gql`
  ${CreateStorageProjectModal.fragment}
  ${ListStorageProjectsPageFiltersPopover.fragment}
  fragment ListStorageProjectsPageFilters on Organization {
    id
    isPrimary
    features {
      isEnabledSalespersonViewAllProjects: isEnabled(feature: "SALESPERSON_VIEW_ALL_PROJECTS")
    }
    ...CreateStorageProjectModal
    ...ListStorageProjectsPageFiltersPopover
  }
`;

ListStorageProjectsPageFilters.countsFragment = gql`
  fragment ListStorageProjectsPageFilters_countsFragment on ProjectCountsByStatus {
    storageActiveCount
    expiredCount
    completedCount
    cancelledCount
  }
`;

export default ListStorageProjectsPageFilters;
