// Libraries

// Supermove
import {useCallback, useEffect, useState} from '@supermove/hooks';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';

const useManagerRestriction = ({
  handleRestriction,
  isBypassed = false,
}: {
  handleRestriction: (id: string) => void;
  isBypassed?: boolean;
}): {viewerId: string | undefined; isRestricted: boolean} => {
  const {viewer} = useAppContext();
  const [isRestricted, setIsRestricted] = useState(!isBypassed);
  const restrict = useCallback(handleRestriction, []);

  useEffect(() => {
    if (viewer) {
      const isViewerRestricted = !isBypassed && UserRole.getIsSalesperson(viewer.role);

      setIsRestricted(isViewerRestricted);

      if (isViewerRestricted) {
        restrict(viewer.id);
      }
    }
  }, [restrict, isBypassed, viewer]);

  return {
    viewerId: viewer?.id,
    isRestricted,
  };
};

export default useManagerRestriction;
