// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {BillItemType, BillRuleType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Line from 'modules/App/components/Line';
import BillingLibraryBillItemTypesRow from 'modules/Project/Billing/components/BillingLibraryBillItemTypesRow';
import BillingLibraryBillRuleTypesRow from 'modules/Project/Billing/components/BillingLibraryBillRuleTypesRow';

const Container = Styled.View`
`;

const Row = Styled.View`
  z-index: ${({index}) => 100 - index};
  flex-direction: row;
`;

const LabelText = Styled.Text`
  ${Typography.Label2};
`;

const handleAddId = ({form, field, id}) => {
  const ids = _.get(form.values, field);
  form.setFieldValue(field, [...ids, id]);
};

const getBillItemTypeOptions = ({form, billItemTypes}) => {
  const billItemTypeIds = _.get(form.values, 'billTypeForm.billItemTypeIds');
  const sortedBillItemTypes = _.sortBy(billItemTypes, (billItemType) =>
    _.includes(billItemTypeIds, billItemType.id),
  );
  return sortedBillItemTypes
    .filter((billItemType) => !billItemType.isParent)
    .map((billItemType) => ({
      label: `${BillItemType.getDisplayName(billItemType)} (${BillItemType.getDisplayValue(
        billItemType,
      )})`,
      value: billItemType.id,
      isDisabled: _.includes(billItemTypeIds, billItemType.id),
    }));
};

const getBillRuleTypeOptions = ({form, billRuleTypes}) => {
  const billRuleTypeIds = _.get(form.values, 'billTypeForm.billRuleTypeIds');
  const sortedBillRuleTypes = _.sortBy(billRuleTypes, (billRuleType) =>
    _.includes(billRuleTypeIds, billRuleType.id),
  );
  return sortedBillRuleTypes.map((billRuleType) => {
    const displayValue = BillRuleType.getDisplayValue(billRuleType);
    return {
      label: `${billRuleType.name} ${displayValue ? `(${displayValue})` : ''}`,
      value: billRuleType.id,
      isDisabled: _.includes(billRuleTypeIds, billRuleType.id),
    };
  });
};

const BillTypeSearchInput = ({form, field, index, label, options}) => {
  return (
    <Row index={index}>
      <Space width={22} />
      <FieldInput
        {...form}
        component={DropdownInput}
        style={{flex: 1}}
        input={{
          options,
          placeholder: `Add ${label} from Billing Library`,
          backgroundColor: 'transparent',
          setFieldValue: () => {},
          isSearchable: true,
          components: {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          },
          onChangeValue: (value) => {
            handleAddId({form, field, id: value});
          },
          style: {
            flex: 1,
            paddingLeft: 20,
            borderRadius: 0,
            borderColor: colors.gray.border,
            borderTopWidth: _.get(form.values, field).length ? 0 : 1,
          },
        }}
      />
      <Space width={21} />
      <Icon
        source={Icon.Search}
        size={12}
        color={colors.gray.tertiary}
        style={{position: 'absolute', top: 11, left: 32}}
      />
    </Row>
  );
};

const BillItemTypeEditor = ({form, billItemTypes, userId, isEnabledTbdBillItems}) => {
  return (
    <React.Fragment>
      {form.values.billTypeForm.billItemTypeIds.map((billItemTypeId, index) => (
        <BillingLibraryBillItemTypesRow
          key={billItemTypeId}
          form={form}
          field={'billTypeForm.billItemTypeIds'}
          billItemType={_.find(billItemTypes, ['id', _.toString(billItemTypeId)])}
          index={index}
          userId={userId}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
      ))}
      <BillTypeSearchInput
        form={form}
        field={'billTypeForm.billItemTypeIds'}
        index={1}
        label={'Bill Items'}
        options={getBillItemTypeOptions({form, billItemTypes})}
      />
    </React.Fragment>
  );
};

const BillRuleTypeEditor = ({form, billRuleTypes, userId}) => {
  return (
    <React.Fragment>
      {form.values.billTypeForm.billRuleTypeIds.map((billRuleTypeId, index) => (
        <BillingLibraryBillRuleTypesRow
          key={billRuleTypeId}
          form={form}
          field={'billTypeForm.billRuleTypeIds'}
          billRuleType={_.find(billRuleTypes, ['id', _.toString(billRuleTypeId)])}
          index={index}
          userId={userId}
        />
      ))}
      <BillTypeSearchInput
        form={form}
        field={'billTypeForm.billRuleTypeIds'}
        index={2}
        label={'Bill Rules'}
        options={getBillRuleTypeOptions({form, billRuleTypes})}
      />
    </React.Fragment>
  );
};

const BillingLibraryBillTypeEditor = ({form, billingLibrary, userId}) => {
  return (
    <Container>
      <Space height={40} />
      <LabelText>
        Add and reorder bill items. This template will be auto-populated on applicable jobs.
      </LabelText>
      <Space height={24} />
      <FieldInput
        {...form}
        label={'Bill Template Name'}
        name={'billTypeForm.name'}
        input={{
          placeholder: 'Enter a name',
          autoFocus: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Bill Template Description'}
        name={'billTypeForm.description'}
        input={{
          placeholder: 'Enter a description',
        }}
      />
      <Space height={40} />
      <Line />
      <Space height={32} />
      <FieldInput.LabelText>Bill Items</FieldInput.LabelText>
      <Space height={8} />
      <BillItemTypeEditor
        form={form}
        billItemTypes={billingLibrary.billItemTypes}
        userId={userId}
        isEnabledTbdBillItems={billingLibrary.organization.features.isEnabledTbdBillItems}
      />
      <Space height={20} />
      <FieldInput.LabelText>Bill Rules</FieldInput.LabelText>
      <Space height={8} />
      <BillRuleTypeEditor
        form={form}
        billRuleTypes={billingLibrary.billRuleTypes}
        userId={userId}
      />
      <Space height={24} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillTypeEditor.fragment = gql`
  ${BillingLibraryBillItemTypesRow.fragment}
  ${BillingLibraryBillRuleTypesRow.fragment}
  ${BillItemType.getDisplayName.fragment}
  ${BillItemType.getDisplayValue.fragment}
  ${BillRuleType.getDisplayValue.fragment}

  fragment BillingLibraryBillTypeEditor on BillingLibrary {
    id
    billItemTypes {
      id
      name
      isParent
      ...BillingLibraryBillItemTypesRow
      ...BillItemType_getDisplayName
      ...BillItemType_getDisplayValue
    }
    billRuleTypes {
      id
      name
      ...BillingLibraryBillRuleTypesRow
      ...BillRuleType_getDisplayValue
    }
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

export default BillingLibraryBillTypeEditor;
