// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Link, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useDrawer} from '@supermove/hooks';
import {Invoice, ProjectType, Tag} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import Table from '@shared/design/components/Table';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import InvoiceActions from 'modules/Accounting/components/InvoiceActions';
import ViewInvoiceDrawer from 'modules/Accounting/components/ViewInvoiceDrawer';
import TextTooltip from 'modules/App/components/TextTooltip';
import InvoiceExportStatusWithDate from 'modules/Storage/components/InvoiceExportStatusWithDate';
import InvoiceStatusBadge from 'modules/Storage/components/InvoiceStatusBadge';
import RecordPaymentModal from 'modules/Storage/components/RecordPaymentModal';
import TagEmojiButton from 'modules/Tag/components/TagEmojiButton';
import UpsertProjectTagModal from 'modules/Tag/components/UpsertProjectTagModal';

const View = Styled.View``;

const ContentContainer = Styled.View`
  height: 100%;
`;

const TableContainer = Styled.View`
  min-width: 100%;
`;

const Cell = Styled.View`
  flex: 1;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const PrimaryText = Styled.Text`
  ${Typography.Body}
`;

const SecondaryText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectTypeColorIndicator = Styled.Text`
  color: ${({color}) => color};
`;

const LinkWrapper = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link};
`;

const getDisplayDate = (date) => {
  return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
};

const handleCheckAllInvoices = ({setSelectedInvoices, isSelectedAllInvoices, allInvoiceIds}) => {
  if (isSelectedAllInvoices) {
    return setSelectedInvoices([]);
  }
  return setSelectedInvoices(allInvoiceIds);
};

const handleCheckInvoice = ({selectedInvoices, setSelectedInvoices, invoiceId}) => {
  const newSelectedInvoices = _.xor(selectedInvoices, [invoiceId]);
  setSelectedInvoices(newSelectedInvoices);
};

const SelectAllCheckbox = ({invoices, selectedInvoices, setSelectedInvoices}) => {
  const allInvoiceIds = invoices.map((invoice) => invoice.id);
  const isSelectedAllInvoices =
    allInvoiceIds.length === selectedInvoices.length && allInvoiceIds.length !== 0;
  return (
    <Checkbox
      size={16}
      iconSize={12}
      isChecked={isSelectedAllInvoices}
      handleToggle={() =>
        handleCheckAllInvoices({
          setSelectedInvoices,
          isSelectedAllInvoices,
          allInvoiceIds,
        })
      }
      style={{paddingRight: 8}}
    />
  );
};

const SelectInvoiceCheckbox = ({invoice, selectedInvoices, setSelectedInvoices}) => {
  const isSelected = _.includes(selectedInvoices, invoice.id);
  return (
    <Checkbox
      size={16}
      iconSize={12}
      isChecked={isSelected}
      handleToggle={() =>
        handleCheckInvoice({
          selectedInvoices,
          setSelectedInvoices,
          invoiceId: invoice.id,
        })
      }
      style={{paddingVertical: 8, paddingRight: 8}}
    />
  );
};

const ProjectTagButton = ({project, refetch}) => {
  const upsertProjectTagModal = useModal({name: 'Upsert Project Tag Modal', enableTracking: true});
  const tags = Tag.getProjectTagAndTagAssignment(project);
  const tagIds = tags.map((tag) => tag.id);

  return (
    <React.Fragment>
      <TagEmojiButton
        tags={tags}
        numberOfVisibleSelections={3}
        upsertTagModal={upsertProjectTagModal}
      />
      <UpsertProjectTagModal
        key={`${upsertProjectTagModal.key}_${project.id}`}
        tagIds={tagIds}
        projectId={project.id}
        projectUuid={project.uuid}
        refetch={refetch}
        isOpen={upsertProjectTagModal.isOpen}
        handleClose={upsertProjectTagModal.handleClose}
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({
  navigator,
  refetch,
  hideSupplementalFields,
  hideStatus,
  hideServiceType,
  showCheckboxes,
  invoices,
  selectedInvoices,
  setSelectedInvoices,
  isErrorTab,
  showBranchName,
  viewingOrganization,
}) => {
  return [
    // Checkbox
    {
      isHidden: !showCheckboxes,
      width: 48,
      headerContent: () => {
        return (
          <SelectAllCheckbox
            invoices={invoices}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setSelectedInvoices}
          />
        );
      },
      cellContent: ({item: invoice}) => {
        return (
          <ContentContainer style={{alignItems: 'flex-end'}}>
            {invoice.hasEmailSendError ? (
              <TextTooltip text={'Invoice failed to send'}>
                <View>
                  <Icon source={Icon.ExclamationTriangle} size={16} color={colors.red.warning} />
                </View>
              </TextTooltip>
            ) : (
              <SelectInvoiceCheckbox
                invoice={invoice}
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
              />
            )}
          </ContentContainer>
        );
      },
    },
    {
      flex: 0.5,
      isHidden:
        hideSupplementalFields || !viewingOrganization.features.isEnabledProjectTagMultibranch,
      headerContent: () => {
        return <HeaderText>Tags</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        return (
          <Table.PreventPropagationContainer>
            <ProjectTagButton project={invoice.project} refetch={refetch} />
          </Table.PreventPropagationContainer>
        );
      },
    },
    // Status
    {
      flex: 2,
      isHidden: hideStatus,
      headerContent: () => {
        return <HeaderText>Status</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        return (
          <Row>
            {!showCheckboxes && invoice.hasEmailSendError && (
              <React.Fragment>
                <TextTooltip text={'Invoice failed to send'}>
                  <View>
                    <Icon source={Icon.ExclamationTriangle} size={16} color={colors.red.warning} />
                  </View>
                </TextTooltip>
                <Space width={12} />
              </React.Fragment>
            )}
            <InvoiceStatusBadge invoice={invoice} />
          </Row>
        );
      },
    },
    // Client Name
    {
      flex: 2,
      isHidden: hideSupplementalFields,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Client Name</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        const {
          project: {client},
        } = invoice;
        const hasSeparateContactName = client.name !== client.primaryContact.fullName;

        return (
          <Cell>
            <LinkWrapper>
              <Link to={`/clients/${client.uuid}`}>
                <LinkText numberOfLines={1}>{client.name}</LinkText>
              </Link>
            </LinkWrapper>
            <SecondaryText numberOfLines={1}>
              {hasSeparateContactName ? client.primaryContact.fullName : ''}
            </SecondaryText>
          </Cell>
        );
      },
    },
    // Invoice Number
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Invoice No.</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        return <LinkText>{invoice.identifier}</LinkText>;
      },
    },
    // Due Date
    {
      flex: 2,
      isHidden: isErrorTab,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Due Date</HeaderText>;
      },

      cellContent: ({item: invoice}) => {
        return <PrimaryText>{invoice.dueDate ? getDisplayDate(invoice.dueDate) : '-'}</PrimaryText>;
      },
    },
    // Invoice Date
    {
      flex: 2,
      isHidden: isErrorTab,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Invoice Date</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        return <PrimaryText>{`${invoice.date ? getDisplayDate(invoice.date) : '-'}`}</PrimaryText>;
      },
    },
    // Invoice Total
    {
      flex: 2,
      isHidden: isErrorTab,
      headerContent: () => {
        return (
          <Cell>
            <HeaderText numberOfLines={1}>Invoice Total</HeaderText>
            <SecondaryText numberOfLines={1}>Remaining Bal.</SecondaryText>
          </Cell>
        );
      },
      cellContent: ({item: invoice}) => {
        return (
          <Cell>
            <PrimaryText>{Currency.display(invoice.grandTotal)}</PrimaryText>
            <SecondaryText>{Currency.display(invoice.remainingBalance)}</SecondaryText>
          </Cell>
        );
      },
    },
    // Bill To
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Bill To</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        const billToClient = invoice.project.billingClient;
        return (
          <Cell>
            <PrimaryText numberOfLines={1}>{billToClient.name}</PrimaryText>
            <SecondaryText numberOfLines={1}>{billToClient.primaryContact.email}</SecondaryText>
          </Cell>
        );
      },
    },
    // Project Details
    {
      flex: 2,
      isHidden: hideSupplementalFields,
      headerContent: () => {
        return (
          <Cell>
            <HeaderText numberOfLines={1}>Project ID</HeaderText>
            <SecondaryText numberOfLines={1}>Project Name</SecondaryText>
          </Cell>
        );
      },
      cellContent: ({item: invoice}) => {
        const {project} = invoice;
        const projectUrl =
          project.projectType.category === ProjectTypeCategory.STORAGE
            ? `/storage/projects/${project.uuid}`
            : `/projects/${project.uuid}`;
        return (
          <Cell>
            <LinkWrapper>
              <Link to={projectUrl}>
                <LinkText numberOfLines={1}>{`Project-${project.identifier}`}</LinkText>
              </Link>
            </LinkWrapper>
            <SecondaryText numberOfLines={1}>{project.name}</SecondaryText>
          </Cell>
        );
      },
    },
    // Project Type
    {
      flex: 2,
      isHidden: hideSupplementalFields || isErrorTab,
      headerContent: () => {
        return (
          <Cell>
            <HeaderText numberOfLines={1}>Project Type</HeaderText>
            {!hideServiceType && <SecondaryText numberOfLines={1}>Service Type</SecondaryText>}
            {hideServiceType && showBranchName && (
              <SecondaryText numberOfLines={1}>Branch</SecondaryText>
            )}
          </Cell>
        );
      },
      cellContent: ({item: invoice}) => {
        const {
          project: {projectType},
        } = invoice;
        return (
          <Cell>
            <PrimaryText numberOfLines={1}>
              <ProjectTypeColorIndicator color={projectType.color}>•</ProjectTypeColorIndicator>
              <Space width={7} />
              {projectType.name}
            </PrimaryText>
            {!hideServiceType && (
              <SecondaryText numberOfLines={1}>
                <Space width={16} />
                {ProjectType.getDisplayCategory(projectType)}
              </SecondaryText>
            )}
            {hideServiceType && showBranchName && (
              <SecondaryText numberOfLines={1}>
                <Space width={16} />
                {invoice.project.owningOrganization.name}
              </SecondaryText>
            )}
          </Cell>
        );
      },
    },
    // Last Exported Status
    {
      // New logic for export column should only show on isPrimary
      isHidden:
        (!viewingOrganization.activeCodatIntegration && !viewingOrganization.isOwnerOfSettings) ||
        isErrorTab,
      flex: 1,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Export</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        return (
          <React.Fragment>
            <Space width={16} />
            <InvoiceExportStatusWithDate invoice={invoice} />
          </React.Fragment>
        );
      },
    },
    // Error Message (only shown if applicable)
    {
      isHidden: !isErrorTab,
      flex: 6,
      headerContent: () => {
        return <HeaderText numberOfLines={1}>Error</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        return (
          <Cell>
            <PrimaryText numberOfLines={1}>
              Invoice email failed to send, see our{' '}
              <a href={Invoice.EMAIL_ERROR_HELP_LINK} target={'_blank'}>
                <LinkText>help article</LinkText>
              </a>{' '}
              for more information
            </PrimaryText>
          </Cell>
        );
      },
    },
    // Actions
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Actions</HeaderText>;
      },
      cellContent: ({item: invoice}) => {
        if (invoice.status !== InvoiceStatus.CANCELLED) {
          return (
            <React.Fragment>
              <Space width={16} />
              <Table.PreventPropagationContainer>
                <InvoiceActions
                  key={`INVOICE_ACTION-${invoice.id}`}
                  invoice={invoice}
                  navigator={navigator}
                  refetch={refetch}
                />
              </Table.PreventPropagationContainer>
            </React.Fragment>
          );
        }
      },
    },
  ];
};

const RowHookComponent = ({isOpen, handleClose, item, refetch}) => {
  return (
    <ViewInvoiceDrawer
      key={isOpen}
      invoiceUuid={item.uuid}
      invoiceIdentifier={item.identifier}
      isOpen={isOpen}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

const InvoiceList = ({
  invoices,
  loading,
  refetch,
  hasError,
  hideSupplementalFields,
  hideStatus,
  hideServiceType,
  showCheckboxes,
  selectedInvoices,
  setSelectedInvoices,
  isErrorTab,
  isFixedHeaderScroll,
  showBranchName,
  viewingOrganization,
}) => {
  const {navigator} = useNavigationDOM();
  const {isEnabledStorageInvoicePayment} = viewingOrganization.features;

  if (isFixedHeaderScroll) {
    return (
      <React.Fragment>
        <Table.FixedHeaderScroll
          columnDefinitions={getColumnDefinitions({
            navigator,
            hideSupplementalFields,
            hideStatus,
            hideServiceType,
            showBranchName,
            showCheckboxes,
            invoices,
            selectedInvoices,
            setSelectedInvoices,
            isErrorTab,
            refetch,
            viewingOrganization,
          })}
          items={invoices}
          loading={loading}
          emptyStateText={'No invoices to display'}
          scrollViewStyle={{paddingBottom: 24}}
          containerStyle={{overflow: 'visible'}}
          hasError={hasError}
          isClickable={isEnabledStorageInvoicePayment}
          rowHook={{
            hook: useDrawer,
            hookArgument: {name: 'View Invoice Drawer'},
            Component: RowHookComponent,
            componentProps: {
              refetch,
            },
          }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <ScrollView>
        <ContentContainer style={{minHeight: 50, flex: 1}}>
          <ScrollView horizontal contentContainerStyle={{flex: 1}}>
            <TableContainer>
              <Table
                columnDefinitions={getColumnDefinitions({
                  navigator,
                  hideSupplementalFields,
                  hideStatus,
                  hideServiceType,
                  showCheckboxes,
                  selectedInvoices,
                  isErrorTab,
                  refetch,
                  viewingOrganization,
                })}
                items={invoices}
                loading={loading}
                emptyStateText={'No invoices to display'}
                hasError={hasError}
                isClickable={isEnabledStorageInvoicePayment}
                rowHook={{
                  hook: useDrawer,
                  hookArgument: {name: 'View Invoice Drawer'},
                  Component: RowHookComponent,
                  componentProps: {
                    refetch,
                  },
                }}
              />
            </TableContainer>
          </ScrollView>
        </ContentContainer>
      </ScrollView>
    );
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceList.fragment = gql`
  ${RecordPaymentModal.fragment}
  ${InvoiceExportStatusWithDate.fragment}
  ${InvoiceStatusBadge.fragment}
  ${Invoice.getInvoiceActions.fragment}
  ${ProjectType.getDisplayCategory.fragment}
  ${Tag.getProjectTagAndTagAssignment.fragment}

  fragment InvoiceList on Invoice {
    id
    uuid
    status
    date
    identifier
    grandTotal
    remainingBalance
    isRecurring
    dueDate
    hasEmailSendError
    project {
      id
      uuid
      identifier
      name
      billingClientId
      billingClient {
        id
        name
        primaryContact {
          id
          email
        }
      }
      owningOrganization {
        id
        name
      }
      client {
        id
        name
        uuid
        primaryContact {
          id
          fullName
          email
        }
      }
      currentPrimaryBill {
        id
        uuid
      }
      projectType {
        id
        name
        color
        ...ProjectType_getDisplayCategory
      }
      ...Tag_getProjectTagAndTagAssignment
    }
    organization {
      id
      slug
      isPrimaryMultibranch
      name
    }
    ...InvoiceExportStatusWithDate
    ...RecordPaymentModal
    ...InvoiceStatusBadge
    ...Invoice_getInvoiceActions
  }

  fragment InvoiceList_Viewer on User {
    id
    viewingOrganization {
      id
      isOwnerOfSettings
      activeCodatIntegration {
        id
      }
      features {
        isEnabledProjectTagMultibranch: isEnabled(feature: "PROJECT_TAG_MULTIBRANCH")
        isEnabledStorageInvoicePayment: isEnabled(feature: "STORAGE_INVOICE_PAYMENT")
      }
    }
  }
`;

export default InvoiceList;
