// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import BillsListItemPostSubtotalBillItemsList from 'modules/Project/Billing/components/BillsListItemPostSubtotalBillItemsList';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
`;

const LineItemName = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  flex: 4;
`;

const LineItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const LineItemDetailText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  text-align: right;
`;

const MobileLineItemText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const AlignRightColumn = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.secondary};
`;

const LineItemDetail = ({icon, children, flex}) => {
  const responsive = useResponsive();
  return (
    <LineItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <LineItemDetailText responsive={responsive}>{children}</LineItemDetailText>
      </Row>
    </LineItemDetailContainer>
  );
};

const BillsListItemSubtotalSection = ({bill, isEditable}) => {
  const responsive = useResponsive();
  const {estimateSubtotalMin: min, estimateSubtotalMax: max} = bill;
  const subtotal = bill.isSubtotalAvailable ? Currency.formatRange({min, max}) : 'TBD';
  return (
    <React.Fragment>
      <Space height={isEditable ? 12 : 8} />
      {responsive.mobile ? (
        <SplitRow>
          <MicroLabelText responsive={responsive}>Subtotal</MicroLabelText>
          <MicroLabelText responsive={responsive}>{subtotal}</MicroLabelText>
        </SplitRow>
      ) : (
        <Row>
          <AlignRightColumn>
            <BillTotalText responsive={responsive}>Subtotal</BillTotalText>
          </AlignRightColumn>
          <LineItemDetail flex={3}>
            <BillTotalText responsive={responsive}>{subtotal}</BillTotalText>
          </LineItemDetail>
        </Row>
      )}
      <BillsListItemPostSubtotalBillItemsList bill={bill} />
      {bill.tip > 0 && (
        <React.Fragment>
          {responsive.mobile ? (
            <SplitRow>
              <MobileLineItemText responsive={responsive}>Tip</MobileLineItemText>
              <MobileLineItemText responsive={responsive}>
                {Currency.format({value: bill.tip})}
              </MobileLineItemText>
            </SplitRow>
          ) : (
            <React.Fragment>
              <Space height={4} />
              <Row>
                <AlignRightColumn>
                  <LineItemName responsive={responsive}>Tip</LineItemName>
                </AlignRightColumn>
                <LineItemDetail flex={3}>{Currency.format({value: bill.tip})}</LineItemDetail>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {(bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0) && (
        <React.Fragment>
          <Space height={4} />
          {responsive.mobile ? (
            <SplitRow>
              <MobileLineItemText responsive={responsive}>{`Sales Tax (${Percent.display(
                bill.project.salesTaxRate,
              )})`}</MobileLineItemText>
              <MobileLineItemText responsive={responsive}>
                {Currency.formatRange({
                  min: bill.estimateSalesTaxAmountMin,
                  max: bill.estimateSalesTaxAmountMax,
                })}
              </MobileLineItemText>
            </SplitRow>
          ) : (
            <Row>
              <AlignRightColumn>
                <LineItemName responsive={responsive}>{`Sales Tax (${Percent.display(
                  bill.project.salesTaxRate,
                )})`}</LineItemName>
              </AlignRightColumn>
              <LineItemDetail flex={3}>
                {Currency.formatRange({
                  min: bill.estimateSalesTaxAmountMin,
                  max: bill.estimateSalesTaxAmountMax,
                })}
              </LineItemDetail>
            </Row>
          )}
        </React.Fragment>
      )}
      <Space height={isEditable ? 12 : 8} />
      <Line dashed={isEditable} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemSubtotalSection.fragment = gql`
  ${BillsListItemPostSubtotalBillItemsList.fragment}

  fragment BillsListItemSubtotalSection on Bill {
    id
    isSubtotalAvailable
    estimateSubtotalMin
    estimateSubtotalMax
    tip
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    ...BillsListItemPostSubtotalBillItemsList
    project {
      id
      salesTaxRate
    }
  }
`;

export default BillsListItemSubtotalSection;
