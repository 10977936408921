// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useHover, useSidebar, usePopover, useNavigationDOM} from '@supermove/hooks';
import {CostItemType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';
import useDeleteCostItemTypeMutation from '@shared/modules/Billing/hooks/useDeleteCostItemTypeMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SidebarPage from 'modules/App/components/SidebarPage';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';
import BillingLibraryPageNavigationTabs from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageNavigationTabs';
import CreateCostItemTypeSidebar from 'modules/Organization/Settings/BillingLibraries/components/CreateCostItemTypeSidebar';
import UpdateCostItemTypeSidebar from 'modules/Organization/Settings/BillingLibraries/components/UpdateCostItemTypeSidebar';

const Container = Styled.View`
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-top: 12px;
  padding-horizontal: ${({mobile}) => (mobile ? 12 : 24)}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const Button = Styled.ButtonV2`
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  border-radius: 4px;
  background-color: ${colors.blue.interactive};
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.white};
`;

const TableContainer = Styled.View`
  flex-direction: row;
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const TableCellButton = Styled.ButtonV2`
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const AddButton = ({children, onPress}) => {
  return (
    <Button onPress={onPress}>
      <Icon source={Icon.Plus} size={10} color={colors.white} />
      <Space width={5} />
      <ButtonText>{children}</ButtonText>
    </Button>
  );
};

const DeleteCostItemTypeModal = ({costItemType, isOpen, handleClose, refetch}) => {
  const {handleSubmit} = useDeleteCostItemTypeMutation({
    costItemTypeId: costItemType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Are you sure you want to delete ${costItemType.name}?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const CostItemTypeActionsPopover = ({costItemTypeActionsPopover, deleteCostItemTypeModal}) => {
  const {isHovered, ref} = useHover();
  const handleOpenDeleteCostItemTypeModal = () => {
    deleteCostItemTypeModal.handleOpen();
    costItemTypeActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={costItemTypeActionsPopover.isOpen}
      handleOpen={costItemTypeActionsPopover.handleOpen}
      handleClose={costItemTypeActionsPopover.handleClose}
      reference={costItemTypeActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuOptionButton
          onPress={handleOpenDeleteCostItemTypeModal}
          isHovered={isHovered}
          ref={ref}
        >
          <MenuItemText>Delete</MenuItemText>
        </MenuOptionButton>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const CostItemTypeActions = ({costItemType, billItemTypes, billTypes, refetch}) => {
  const editCostItemTypeSidebar = useSidebar({name: 'EDIT_COST_ITEM_TYPE_SIDEBAR'});
  const costItemTypeActionsPopover = usePopover();
  const deleteCostItemTypeModal = useModal({name: 'DELETE_COST_ITEM_TYPE_MODAL'});
  return (
    <React.Fragment>
      <Row>
        <TableCellButton onPress={editCostItemTypeSidebar.handleOpen}>
          <Icon source={Icon.Pen} color={colors.gray.secondary} size={16} />
        </TableCellButton>
        <Space width={25} />
        <Popover.Content innerRef={costItemTypeActionsPopover.ref}>
          <TableCellButton onPress={costItemTypeActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </TableCellButton>
        </Popover.Content>
      </Row>
      <UpdateCostItemTypeSidebar
        key={editCostItemTypeSidebar.key}
        isOpen={editCostItemTypeSidebar.isOpen}
        handleClose={editCostItemTypeSidebar.handleClose}
        costItemType={costItemType}
        category={CostCategoryKind.OTHER}
        header={'Edit Cost Item'}
        billItemTypeOptions={billItemTypes}
        billTypeOptions={billTypes}
        refetch={refetch}
      />
      <CostItemTypeActionsPopover
        key={`COST_ITEM_TYPE_ACTIONS_POPOVER-${costItemTypeActionsPopover.isOpen}`}
        costItemTypeActionsPopover={costItemTypeActionsPopover}
        deleteCostItemTypeModal={deleteCostItemTypeModal}
      />
      <DeleteCostItemTypeModal
        key={deleteCostItemTypeModal.key}
        isOpen={deleteCostItemTypeModal.isOpen}
        handleClose={deleteCostItemTypeModal.handleClose}
        costItemType={costItemType}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({billItemTypes, billTypes, existingCostItemTypes, refetch}) => {
  return [
    {
      width: 100,
      headerContent: () => {
        return <TableHeaderText>Actions</TableHeaderText>;
      },
      cellContent: ({item}) => (
        <CostItemTypeActions
          costItemType={item}
          billItemTypes={billItemTypes.filter((billItemType) => {
            // Don't include billItemTypes that already have cost items
            const existingCostItemTypeForBillItemType = _.find(
              existingCostItemTypes,
              (existingCostItemType) => {
                return existingCostItemType.billItemTypeId === _.toNumber(billItemType.id);
              },
            );

            return (
              !existingCostItemTypeForBillItemType ||
              item.id === existingCostItemTypeForBillItemType.id
            );
          })}
          billTypes={billTypes}
          refetch={refetch}
        />
      ),
    },
    {
      width: 160,
      headerContent: () => {
        return <TableHeaderText>Type</TableHeaderText>;
      },
      cellContent: ({item}) => <TableCellText>{CostItemType.getDisplayKind(item)}</TableCellText>,
    },
    {
      width: 160,
      headerContent: () => {
        return <TableHeaderText>Item Name</TableHeaderText>;
      },
      cellContent: ({item}) => <TableCellText>{CostItemType.getDisplayName(item)}</TableCellText>,
    },
    {
      width: 160,
      headerContent: () => {
        return <TableHeaderText>Rate</TableHeaderText>;
      },
      cellContent: ({item}) => {
        // NOTE(sam): Cost Item Types only have one rate
        const costItemTypeRate = item.costItemTypeRates[0];

        if (costItemTypeRate.rate === 0) {
          return null;
        }

        return (
          <TableCellText>
            {item.unit === CostItemUnit.PERCENT
              ? Percent.display(costItemTypeRate.rate)
              : Currency.display(costItemTypeRate.rate, {shouldHideCentsIfZero: true})}
            {CostItemType.getDisplaySuffixFromUnit(item.unit)}
          </TableCellText>
        );
      },
    },
  ];
};

const BillingLibraryCostItemTypesPageContent = ({billingLibrary, refetch}) => {
  const newCostItemTypeSidebar = useSidebar({name: 'CREATE_COST_ITEM_TYPE_SIDEBAR'});

  const costItemTypes = _.sortBy(billingLibrary.costItemTypes, 'kind');

  return (
    <React.Fragment>
      <Space height={28} />
      <Row>
        <HeaderText>Additional Costs</HeaderText>
        <Space width={40} />
        <AddButton onPress={newCostItemTypeSidebar.handleOpen}>New Cost Item</AddButton>
      </Row>
      <Space height={12} />
      <TableContainer>
        <Table
          columnDefinitions={getColumnDefinitions({
            billTypes: billingLibrary.billTypes,
            billItemTypes: billingLibrary.billItemTypes.filter(
              (billItemType) => !billItemType.isParent,
            ),
            existingCostItemTypes: billingLibrary.costItemTypes,
            refetch,
          })}
          items={costItemTypes}
          emptyStateText={'No items to display'}
          isDense
          headerStyle={{backgroundColor: colors.gray.background}}
        />
      </TableContainer>
      <Space height={50} />
      <CreateCostItemTypeSidebar
        key={newCostItemTypeSidebar.key}
        isOpen={newCostItemTypeSidebar.isOpen}
        handleClose={newCostItemTypeSidebar.handleClose}
        category={CostCategoryKind.OTHER}
        header={'Add Cost Item'}
        billingLibrary={billingLibrary}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const BillingLibraryCostItemTypesPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPage
      selected={'settings'}
      query={BillingLibraryCostItemTypesPage.query}
      variables={{
        billingLibraryUuid: params.billingLibraryUuid,
        category: CostCategoryKind.OTHER,
      }}
      fetchPolicy={'cache-and-network'}
    >
      {({data, refetch}) => {
        return (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            <ContentContainer>
              <BillingLibraryPageNavigationTabs billingLibrary={data.billingLibrary} />
              <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
                <ScrollView>
                  <BillingLibraryCostItemTypesPageContent
                    billingLibrary={data.billingLibrary}
                    refetch={refetch}
                  />
                </ScrollView>
              </ScrollView>
            </ContentContainer>
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryCostItemTypesPage.query = gql`
  ${BillingLibraryPageHeader.fragment}
  ${BillingLibraryPageNavigationTabs.fragment}
  ${CreateCostItemTypeSidebar.fragment}
  ${UpdateCostItemTypeSidebar.fragment}
  ${CostItemType.getDisplayKind.fragment}
  ${CostItemType.getDisplayName.fragment}

  query BillingLibraryCostItemTypesPage(
    $billingLibraryUuid: String!,
    $category: String!,
  ) {
    ${gql.query}
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      costItemTypes(category: $category) {
        id
        billItemTypeId
        ...UpdateCostItemTypeSidebar
        ...CostItemType_getDisplayKind
        ...CostItemType_getDisplayName
      }
      billItemTypes {
        id
        name
        isParent
      }
      billTypes {
        id
        name
      }
      ...BillingLibraryPageHeader
      ...BillingLibraryPageNavigationTabs
      ...CreateCostItemTypeSidebar
    }
  }
`;

export default BillingLibraryCostItemTypesPage;
