// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {BillItem} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
`;

const FlexContainer = Styled.View`
  flex: ${(props) => props.flex};
`;

const CaptionText = Styled.Text`
  ${Typography.Body4}
  color: ${(props) => (props.color ? props.color : colors.gray.secondary)};
`;

const BillItemName = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
  flex: 4;
`;

const BillItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const BillItemDetailText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => props.color};
  text-align: right;
`;

const ListEmptyMessageContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  padding: 8px;
  align-items: center;
`;

const BillItemDetail = ({icon, children, color, flex}) => {
  return (
    <BillItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <BillItemDetailText color={color}>{children}</BillItemDetailText>
      </Row>
    </BillItemDetailContainer>
  );
};

const DiscountTag = () => {
  return (
    <React.Fragment>
      <Icon source={Icon.Tag} size={10} color={colors.green.status} />
      <Space width={4} />
    </React.Fragment>
  );
};

const QuantityColumn = ({billItem, isEnabledTbdBillItems}) => {
  const quantity = BillItem.getEstimateQuantity(billItem, {isEnabledTbdBillItems});
  return (
    <BillItemDetail color={colors.gray.secondary} flex={2}>
      {quantity}
    </BillItemDetail>
  );
};

const PriceColumn = ({billItem, isTotalVisible, isEnabledTbdBillItems}) => {
  const price = BillItem.getDisplayPrice(billItem, {isEnabledTbdBillItems});
  return (
    <BillItemDetail color={colors.gray.secondary} flex={isTotalVisible ? 2 : 3}>
      {price}
    </BillItemDetail>
  );
};

const TotalColumn = ({billItem, isEnabledTbdBillItems}) => {
  const total = BillItem.getEstimateTotal(billItem, {isEnabledTbdBillItems});
  const totalColor = billItem.isCredit ? colors.green.status : colors.gray.primary;
  return (
    <BillItemDetail flex={3} color={totalColor} icon={billItem.isCredit ? <DiscountTag /> : null}>
      {total}
    </BillItemDetail>
  );
};

const FlexSpaceHolder = ({isQuantityVisible, isTotalVisible}) => {
  // This fills any additional space created from fields being disabled.
  // For every field we take away we add to the flex value to evenly compensate.
  let totalFlexSpace = 0;
  if (!isQuantityVisible) {
    totalFlexSpace += 2;
  }
  if (!isTotalVisible) {
    totalFlexSpace += 2;
  }
  return <BillItemDetailContainer flex={totalFlexSpace} />;
};

const Description = ({description}) => {
  return (
    <Row>
      <FlexContainer flex={5}>
        {!!description && <Space height={2} />}
        <CaptionText color={colors.gray.secondary}>{description}</CaptionText>
      </FlexContainer>
      <FlexContainer flex={6} />
    </Row>
  );
};

const BillBillItem = ({hasQuantity, hasTotal, billItem, isEnabledTbdBillItems}) => {
  return (
    <React.Fragment>
      <Row>
        <BillItemName>{billItem.name}</BillItemName>
        <FlexSpaceHolder isQuantityVisible={hasQuantity} isTotalVisible={hasTotal} />
        {hasQuantity && (
          <QuantityColumn billItem={billItem} isEnabledTbdBillItems={isEnabledTbdBillItems} />
        )}
        <PriceColumn
          billItem={billItem}
          isTotalVisible={hasTotal}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
        {hasTotal && (
          <TotalColumn billItem={billItem} isEnabledTbdBillItems={isEnabledTbdBillItems} />
        )}
      </Row>
      <Description description={billItem.description} />
    </React.Fragment>
  );
};

const NoBillItemsPlaceholderMessage = () => {
  return (
    <ListEmptyMessageContainer>
      <CaptionText color={colors.gray.tertiary}>No items added to this bill yet.</CaptionText>
    </ListEmptyMessageContainer>
  );
};

const BillingDocumentBillPreSubtotalBillItems = ({bill, hasQuantity, hasTotal}) => {
  return (
    <React.Fragment>
      {bill.billItemsPreSubtotal.map((billItem, index) => {
        return (
          <React.Fragment key={`BILL_ITEM-${index}`}>
            {index > 0 && <Space height={4} />}
            <BillBillItem
              hasQuantity={hasQuantity}
              hasTotal={hasTotal}
              billItem={billItem}
              isEnabledTbdBillItems={bill.organization.features.isEnabledTbdBillItems}
            />
          </React.Fragment>
        );
      })}
      {_.isEmpty(bill.billItemsPreSubtotal) && <NoBillItemsPlaceholderMessage />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillingDocumentBillPreSubtotalBillItems.fragment = gql`
  ${BillItem.getDisplayPrice.fragment}
  ${BillItem.getEstimateQuantity.fragment}
  ${BillItem.getEstimateTotal.fragment}

  fragment BillingDocumentBillPreSubtotalBillItems on Bill {
    id
    billItemsPreSubtotal {
      id
      name
      description
      isCredit
      ...BillItem_getDisplayPrice
      ...BillItem_getEstimateQuantity
      ...BillItem_getEstimateTotal
    }
    organization {
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

export default BillingDocumentBillPreSubtotalBillItems;
