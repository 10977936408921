// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useScrollView, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import EditBillBillItem from 'modules/Project/Billing/Bill/Edit/components/EditBillBillItem';
import EditBillBillRule from 'modules/Project/Billing/Bill/Edit/components/EditBillBillRule';
import EditBillSlideHeader from 'modules/Project/Billing/Bill/Edit/components/EditBillSlideHeader';

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border}
`;

const Container = Styled.View`
  flex: 1;
`;

const ListHeaderContainer = Styled.View`
  align-items: flex-end;
  padding-right: 12px;
`;

const ReorderButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({isOn}) => (isOn ? colors.blue.interactive : colors.gray.border)};
  background-color: ${({isOn}) => (isOn ? colors.blue.interactive : colors.white)};
`;

const ReorderText = Styled.Text`
  ${Typography.Label1}
  color: ${({color}) => color};
`;

const SwitchOuterOval = Styled.View`
  height: 16px;
  width: 24px;
  background-color: ${({isOn}) => (isOn ? colors.white : colors.gray.secondary)};
  border-radius: 20px;
  justify-content: center;
  align-items: ${({isOn}) => (isOn ? 'flex-end' : 'flex-start')};
  padding: 2px;
`;

const SwitchInnerCircle = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${({isOn}) => (isOn ? colors.blue.interactive : colors.white)};
`;

const ListSectionTitle = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
  padding-left: 16px;
`;

const handleRemoveBillItemForm = ({form, field, billItemForm}) => {
  const deletedBillItemFormsField = 'billForm.deletedBillItemForms';
  const deletedBillItemForm = _.get(form.values, `${field}.${billItemForm.index}`);
  form.setFieldValue(deletedBillItemFormsField, [
    ..._.get(form.values, deletedBillItemFormsField),
    deletedBillItemForm,
  ]);
  const updatedBillItemForms = _.filter(
    _.get(form.values, field),
    (form) => form.index !== billItemForm.index,
  );
  form.setFieldValue(
    field,
    updatedBillItemForms.map((billItemForm, index) => ({...billItemForm, index})),
  );
};

const handleRemoveBillRuleForm = ({form, index}) => {
  const field = 'billForm.billRuleForms';
  const billRuleForms = _.get(form.values, field);
  form.setFieldValue(
    field,
    billRuleForms.filter((form, formIndex) => formIndex !== index),
  );
};

const handleChangeOrder = ({form, field, fromIndex, toIndex, isIndexed}) => {
  const forms = _.get(form.values, field);
  const reorderedForms = List.move({list: forms, fromIndex, toIndex});
  if (isIndexed) {
    form.setFieldValue(
      field,
      reorderedForms.map((form, updatedIndex) => ({...form, index: updatedIndex})),
    );
  } else {
    form.setFieldValue(field, reorderedForms);
  }
};

const Switch = ({isOn}) => {
  return (
    <SwitchOuterOval isOn={isOn}>
      <SwitchInnerCircle isOn={isOn} />
    </SwitchOuterOval>
  );
};

const ListHeader = ({isReordering, setIsReordering}) => {
  return (
    <ListHeaderContainer>
      <Space height={12} />
      <ReorderButton onPress={() => setIsReordering(!isReordering)} isOn={isReordering}>
        <Switch isOn={isReordering} />
        <Space width={8} />
        <ReorderText color={isReordering ? colors.white : colors.gray.secondary}>
          Reorder
        </ReorderText>
      </ReorderButton>
    </ListHeaderContainer>
  );
};

const BillItemRow = ({
  billItemForm,
  form,
  isBillItemPostSubtotalForm,
  isReordering,
  numberOfBillItemFormsPreSubtotal,
  numberOfBillItemFormsPostSubtotal,
}) => {
  return (
    <EditBillBillItem
      billItemForm={billItemForm}
      form={form}
      index={billItemForm.index}
      handleRemoveBillItemForm={() => {
        handleRemoveBillItemForm({
          form,
          field: isBillItemPostSubtotalForm
            ? 'billForm.billItemFormsPostSubtotal'
            : 'billForm.billItemFormsPreSubtotal',
          billItemForm,
        });
      }}
      handleChangeOrder={(toIndex) => {
        handleChangeOrder({
          form,
          field: isBillItemPostSubtotalForm
            ? 'billForm.billItemFormsPostSubtotal'
            : 'billForm.billItemFormsPreSubtotal',
          fromIndex: billItemForm.index,
          toIndex,
          isIndexed: true,
        });
      }}
      isReordering={isReordering}
      isLastItem={
        isBillItemPostSubtotalForm
          ? billItemForm.index === numberOfBillItemFormsPostSubtotal - 1
          : billItemForm.index === numberOfBillItemFormsPreSubtotal - 1
      }
    />
  );
};

const BillRuleRow = ({billRuleForm, form, index, isReordering, isLastItem}) => {
  return (
    <EditBillBillRule
      billRuleForm={billRuleForm}
      form={form}
      index={index}
      handleRemoveBillRuleForm={() => {
        handleRemoveBillRuleForm({form, index});
      }}
      handleChangeOrder={(toIndex) => {
        handleChangeOrder({
          form,
          field: 'billForm.billRuleForms',
          fromIndex: index,
          toIndex,
        });
      }}
      isReordering={isReordering}
      isLastItem={isLastItem}
    />
  );
};

const EditBillSlide = ({
  form,
  type,
  showSearch,
  searchPopover,
  handleOpenSearch,
  handleCloseSearch,
  bill,
}) => {
  const {ref, handleScrollToEnd} = useScrollView();
  const [isReordering, setIsReordering] = useState(false);
  const numberOfBillItemFormsPreSubtotal = form.values.billForm.billItemFormsPreSubtotal.length;
  const numberOfBillItemFormsPostSubtotal = form.values.billForm.billItemFormsPostSubtotal.length;
  const numberOfBillRuleForms = form.values.billForm.billRuleForms.length;
  return (
    <Container>
      <EditBillSlideHeader
        billId={bill.id}
        form={form}
        type={type}
        showSearch={showSearch}
        handleOpenSearch={handleOpenSearch}
        handleCloseSearch={handleCloseSearch}
        searchPopover={searchPopover}
        handleScrollToEnd={handleScrollToEnd}
        isEnabledTbdBillItems={bill.organization.features.isEnabledTbdBillItems}
      />
      <Line />
      {type === 'ITEMS' && (
        <React.Fragment>
          <FlatList
            ref={ref}
            listKey={'ITEMS'}
            data={[
              ...form.values.billForm.billItemFormsPreSubtotal,
              ...form.values.billForm.billItemFormsPostSubtotal,
            ]}
            keyExtractor={(form, index) => `ITEMS-${index}`}
            renderItem={({item: billItemForm, index}) => {
              return (
                <React.Fragment>
                  <Space height={12} />
                  {index === 0 && numberOfBillItemFormsPreSubtotal > 0 && (
                    <React.Fragment>
                      <ListSectionTitle>Items before subtotal</ListSectionTitle>
                      <Space height={12} />
                    </React.Fragment>
                  )}
                  {index === numberOfBillItemFormsPreSubtotal && (
                    <React.Fragment>
                      <ListSectionTitle>Items after subtotal</ListSectionTitle>
                      <Space height={12} />
                    </React.Fragment>
                  )}
                  <BillItemRow
                    form={form}
                    billItemForm={billItemForm}
                    isBillItemPostSubtotalForm={index >= numberOfBillItemFormsPreSubtotal}
                    isReordering={isReordering}
                    numberOfBillItemFormsPreSubtotal={numberOfBillItemFormsPreSubtotal}
                    numberOfBillItemFormsPostSubtotal={numberOfBillItemFormsPostSubtotal}
                  />
                </React.Fragment>
              );
            }}
            ListHeaderComponent={
              <ListHeader isReordering={isReordering} setIsReordering={setIsReordering} />
            }
            style={{paddingBottom: 16, backgroundColor: colors.gray.background}}
          />
        </React.Fragment>
      )}
      {type === 'RULES' && (
        <React.Fragment>
          <FlatList
            ref={ref}
            listKey={'RULES'}
            data={form.values.billForm.billRuleForms}
            keyExtractor={(form, index) => `RULES-${index}`}
            renderItem={({item: billRuleForm, index}) => {
              return (
                <BillRuleRow
                  billRuleForm={billRuleForm}
                  form={form}
                  index={index}
                  isLastItem={index === numberOfBillRuleForms - 1}
                  isReordering={isReordering}
                />
              );
            }}
            ListHeaderComponent={
              <ListHeader isReordering={isReordering} setIsReordering={setIsReordering} />
            }
            style={{paddingBottom: 16}}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillSlide.fragment = gql`
  fragment EditBillSlide on Bill {
    id
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

export default EditBillSlide;
