// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  DateInput,
  DropdownInput,
  MultiDropdownInput,
  Space,
  Styled,
  Popover,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Organization, ReferralSource} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const PopoverContainer = Styled.Touchable`
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-top: 32px;
`;

const FilterContainer = Styled.View`
  flex-direction: row;
  zIndex: ${(props) => 1000 - props.index}
`;

const FromDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      component={DateInput}
      name={`${name}.fromDate`}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ToDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      component={DateInput}
      name={`${name}.toDate`}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ProjectTypeFilter = ({index, name, form, projectTypes, isMultiBranch}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.projectTypeIds`}
      label={'Project Type'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: projectTypes.map((projectType) => ({
          label: projectType.name,
          value: projectType.id,
          description: projectType.organizationName || projectType.organization.name,
        })),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        components: {
          Option: (optionData) => (
            <MultiDropdownCheckboxInput.CheckboxOption
              {...optionData}
              isDescriptionBelow={isMultiBranch}
            />
          ),
        },
      }}
    />
  );
};

const SalespeopleMultiselect = ({index, name, form, organization}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.salespersonIds`}
      label={'Salesperson'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: Organization.getSalespersonOptions(organization).map((user) => ({
          label: user.label,
          value: _.toString(user.value), // Keeps filter option selected when page is refreshed
          description: user.description,
        })),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        components: organization.features.isEnabledShowSalespersonsFromAllOrganizations
          ? {
              Option: DropdownInput.OptionWithDescription,
            }
          : {},
      }}
    />
  );
};

const CoordinatorMultiselect = ({index, name, form, organization}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.coordinatorIds`}
      label={'Coordinator'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: Organization.getCoordinatorOptions(organization).map((user) => ({
          label: user.label,
          value: _.toString(user.value), // Keeps filter option selected when page is refreshed
        })),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const ReferralSourceMultiselect = ({index, name, form, organization}) => {
  const {referralSources} = organization.settings;
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.referralSources`}
      label={'Referral Source'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: ReferralSource.getDropdownOptions({referralSources}),
        placeholder: 'Select referral source',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const StorageProjectFilterPopoverContent = ({organization, form, isRestricted, viewerId}) => {
  const {params} = useNavigationDOM();
  const formName = 'filteredProjectsForm';

  const isMultiBranch =
    organization.isPrimaryMultibranch && organization.features.isEnabledMultibranchStorage;

  const multiBranchProjectTypeFilterOptions = Organization.getProjectTypesForMultibranchSlugs(
    organization,
    params.slugs,
  );

  return (
    <ResponsivePopover.StaticContainer width={320}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filter</Header>
        <Space height={8} />
        <Space height={8} />
        <FilterContainer index={1}>
          <FromDateFilter name={formName} form={form} index={2} />
          <Space width={8} />
          <BodyText>-</BodyText>
          <Space width={8} />
          <ToDateFilter name={formName} form={form} index={3} />
        </FilterContainer>
        <Space height={8} />
        {isMultiBranch && (
          <React.Fragment>
            <MultiBranchOrganizationField
              label={'Branch'}
              width={'100%'}
              index={4}
              isPortaled={false}
              value={params.slugs || []}
              organizations={organization.company.organizations}
              disableAllOption
              onChangeValue={(newSlugs) => {
                form.setFieldValue(`${formName}.slugs`, newSlugs);
                form.setFieldValue(`${formName}.projectTypeIds`, null);
              }}
            />
            <Space height={8} />
          </React.Fragment>
        )}
        <ProjectTypeFilter
          projectTypes={
            isMultiBranch ? multiBranchProjectTypeFilterOptions : organization.storageProjectTypes
          }
          form={form}
          name={formName}
          isMultiBranch={isMultiBranch}
          index={5}
        />
        <Space height={8} />
        {!isRestricted && (
          <React.Fragment>
            <SalespeopleMultiselect
              organization={organization}
              form={form}
              name={formName}
              index={6}
            />
            <Space height={8} />
          </React.Fragment>
        )}
        <CoordinatorMultiselect organization={organization} form={form} name={formName} index={7} />
        <Space height={8} />
        <ReferralSourceMultiselect
          organization={organization}
          form={form}
          name={formName}
          index={8}
        />
        <Space height={16} />
        <SecondaryButton
          text={'Clear Filters'}
          onPress={() => {
            form.setFieldValue(`${formName}.salespersonIds`, isRestricted ? [viewerId] : null);
            form.setFieldValue(`${formName}.fromDate`, null);
            form.setFieldValue(`${formName}.toDate`, null);
            form.setFieldValue(`${formName}.coordinatorIds`, null);
            form.setFieldValue(`${formName}.projectTypeIds`, null);
            form.setFieldValue(`${formName}.referralSources`, null);
            form.setFieldValue(
              `${formName}.slugs`,
              organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
            );
          }}
        />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const ListStorageProjectsPageFiltersPopover = ({
  popover,
  organization,
  form,
  isRestricted,
  viewerId,
}) => {
  return (
    <PopoverContainer>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <StorageProjectFilterPopoverContent
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          viewerId={viewerId}
        />
      </Popover>
    </PopoverContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListStorageProjectsPageFiltersPopover.fragment = gql`
  ${Organization.getCoordinatorOptions.fragment}
  ${Organization.getSalespersonOptions.fragment}
  ${ReferralSource.getDropdownOptions.fragment}
  ${MultiBranchOrganizationField.fragment}
  ${Organization.getProjectTypesForMultibranchSlugs.fragment}

  fragment ListStorageProjectsPageFiltersPopover on Organization {
    id
    isPrimary
    isPrimaryMultibranch
    slug
    features {
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
    }
    storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
      id
      name
      organization {
        id
        name
      }
    }
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    company {
      id
      organizations {
        id
        storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
    ...MultiBranchOrganizationField
    ...Organization_getCoordinatorOptions
    ...Organization_getSalespersonOptions
    ...Organization_getProjectTypesForMultibranchSlugs
  }
`;

export default ListStorageProjectsPageFiltersPopover;
