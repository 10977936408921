// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import Sheet from '@shared/design/components/Sheet';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import UserRole from '@shared/modules/User/enums/UserRole';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import useAppContext from 'modules/App/context/useAppContext';
import useManagerRestriction from 'modules/App/hooks/useManagerRestriction';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Subheading}
`;

const FilterContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const getProjectTypeDropdownOptions = (projectTypes) => {
  return _.map(projectTypes, (projectType) => ({
    label: projectType.name,
    value: projectType.id,
  }));
};

const getSalespersonDropdownOptions = (salespersons) => {
  return _.map(salespersons, (salesperson) => ({
    label: salesperson.label,
    value: _.toString(salesperson.value),
  }));
};

const getButtonText = ({params, viewer, organization}) => {
  const {date, slugs, searchQuery, ...countedFilters} = params;
  const activeFilters = _.compact([..._.values(countedFilters)]);
  const count = activeFilters.length;
  const isRestrictedSalesperson =
    !UserRole.hasManagerPermissions(viewer?.role) &&
    !organization.features.isEnabledSalespersonViewAllProjects;
  const adjustedCount = isRestrictedSalesperson ? count - 1 : count;
  return `Filters${adjustedCount ? ` (${adjustedCount})` : ''}`;
};

const ClearFiltersButton = ({
  handleClearProjectsListFilters,
  isRestricted,
  viewerId,
  isDesktop,
}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  return (
    <SecondaryButton
      text={'Clear Filters'}
      onPress={() => {
        handleClearProjectsListFilters({
          baseUrl: location.pathname,
          navigator,
          params,
          isRestricted,
          viewerId,
        });
      }}
      style={isDesktop ? {} : {flex: 1}}
      isResponsive={!isDesktop}
    />
  );
};

const TagsFilter = ({index, handleUpdateParam, organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  const filterByArchivedTag = organization.companySettings.tags.filter((tag) => !tag.isArchived);

  const projectTags = filterByArchivedTag.filter((tag) => tag.category === TagCategory.PROJECT);
  const jobTags = filterByArchivedTag.filter((tag) => tag.category === TagCategory.JOB);

  return (
    <React.Fragment>
      <TagDropdownInputField
        options={
          projectTags
            ? projectTags.map((tag) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={'Select project tag'}
        label={'Project Tag(s)'}
        index={index}
        value={_.get(params, 'projectTagIds', [])}
        onChangeValue={(projectTagIds) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'projectTagIds',
            paramValue: projectTagIds,
          });
        }}
      />
      <Space height={12} />
      <TagDropdownInputField
        options={
          jobTags
            ? jobTags.map((tag) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={'Select job tag'}
        label={'Job Tag(s)'}
        index={index + 1}
        value={_.get(params, 'jobTagIds', [])}
        onChangeValue={(jobTagIds) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'jobTagIds',
            paramValue: jobTagIds,
          });
        }}
      />
    </React.Fragment>
  );
};

const ProjectTypesFilter = ({index, handleUpdateParam, organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Project Type(s)</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownInput
        options={
          organization
            ? getProjectTypeDropdownOptions(Organization.getProjectTypes(organization))
            : []
        }
        placeholder={'Select project type(s)'}
        style={{width: '100%'}}
        value={_.get(params, 'projectTypes', [])}
        onChangeValue={(projectTypes) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'projectTypes',
            paramValue: projectTypes,
          });
        }}
        isSearchable
      />
    </FilterContainer>
  );
};

const SalespersonFilter = ({index, handleUpdateParam, organization}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Salespeople</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownInput
        options={
          organization
            ? getSalespersonDropdownOptions(Organization.getSalespersonOptions(organization))
            : []
        }
        placeholder={'Select salespeople'}
        style={{width: '100%'}}
        value={_.get(params, 'salespersons', [])}
        onChangeValue={(salespersons) => {
          handleUpdateParam({
            baseUrl: location.pathname,
            navigator,
            params,
            paramKey: 'salespersons',
            paramValue: salespersons,
          });
        }}
        isSearchable
      />
    </FilterContainer>
  );
};

const CapacityProjectListPopoverContent = ({
  handleUpdateParam,
  handleClearProjectsListFilters,
  organization,
  header,
  isDesktop,
}) => {
  const {params, navigator} = useNavigationDOM();
  const {location} = navigator;
  const {isRestricted, viewerId} = useManagerRestriction({
    isBypassed: organization.features.isEnabledSalespersonViewAllProjects,
    handleRestriction: (userId) => {
      handleUpdateParam({
        baseUrl: location.pathname,
        navigator,
        params,
        paramKey: 'salespersons',
        paramValue: [userId],
      });
    },
  });

  return (
    <ContentContainer>
      <Space height={16} />
      {!!header && (
        <React.Fragment>
          <Header>{header}</Header>
          <Space height={12} />
        </React.Fragment>
      )}
      <ProjectTypesFilter
        index={1}
        handleUpdateParam={handleUpdateParam}
        organization={organization}
      />
      <Space height={12} />
      {!isRestricted && (
        <React.Fragment>
          <SalespersonFilter
            index={2}
            handleUpdateParam={handleUpdateParam}
            organization={organization}
          />
          <Space height={12} />
        </React.Fragment>
      )}
      {organization.features.isEnabledProjectTag && (
        <React.Fragment>
          <TagsFilter index={3} handleUpdateParam={handleUpdateParam} organization={organization} />
          <Space height={16} />
        </React.Fragment>
      )}
      <ClearFiltersButton
        handleClearProjectsListFilters={handleClearProjectsListFilters}
        isRestricted={isRestricted}
        viewerId={viewerId}
        isDesktop={isDesktop}
      />
      <Space height={16} />
    </ContentContainer>
  );
};

const CapacityProjectListFiltersPopover = ({
  popover,
  handleUpdateParam,
  handleClearProjectsListFilters,
  organization,
  isDesktop,
}) => {
  return isDesktop ? (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={288}>
        <CapacityProjectListPopoverContent
          handleUpdateParam={handleUpdateParam}
          handleClearProjectsListFilters={handleClearProjectsListFilters}
          organization={organization}
          header={'Filters'}
          isDesktop
        />
      </ResponsivePopover.StaticContainer>
    </Popover>
  ) : (
    <Sheet
      isOpen={popover.isOpen}
      handleClose={popover.handleClose}
      headerText={'Filters'}
      isFixedHeight={false}
      isDesktop={isDesktop}
    >
      <CapacityProjectListPopoverContent
        handleUpdateParam={handleUpdateParam}
        handleClearProjectsListFilters={handleClearProjectsListFilters}
        organization={organization}
      />
    </Sheet>
  );
};

const CapacityProjectListFilters = ({
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
}) => {
  const responsive = useResponsive();
  const {viewer} = useAppContext();
  const {params} = useNavigationDOM();
  const capacityProjectListFiltersPopover = usePopover({
    name: 'Capacity project list filters popover',
  });
  const {isEnabledCapacityCalendarResponsiveDayView} = organization.features;
  // TODO(jholston): Remove this variable and only use responsive.desktop after flag has been rolled out.
  const isDesktop = !isEnabledCapacityCalendarResponsiveDayView || responsive.desktop;
  return (
    <React.Fragment>
      <Popover.Content innerRef={capacityProjectListFiltersPopover.ref}>
        <SecondaryButton
          text={isDesktop ? getButtonText({params, viewer, organization}) : null}
          onPress={capacityProjectListFiltersPopover.handleOpen}
          iconLeft={Icon.Filter}
          iconSize={isDesktop ? 12 : 16}
          isResponsive
        />
      </Popover.Content>
      <CapacityProjectListFiltersPopover
        popover={capacityProjectListFiltersPopover}
        handleUpdateParam={handleUpdateParam}
        handleClearProjectsListFilters={handleClearProjectsListFilters}
        organization={organization}
        isDesktop={isDesktop}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityProjectListFilters.fragment = gql`
  ${Organization.getProjectTypes.fragment}
  ${Organization.getSalespersonOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment CapacityProjectListFilters on Organization {
    id
    companySettings {
      tags {
        id
        name
        emoji
        category
        isArchived
        ...TagDropdownInputField
      }
    }
    features {
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
      isEnabledSalespersonViewAllProjects: isEnabled(feature: "SALESPERSON_VIEW_ALL_PROJECTS")
      isEnabledCapacityCalendarResponsiveDayView: isEnabled(
        feature: "CAPACITY_CALENDAR_RESPONSIVE_DAY_VIEW"
      )
    }
    ...Organization_getProjectTypes
    ...Organization_getSalespersonOptions
  }
`;

export default CapacityProjectListFilters;
