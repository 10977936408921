// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsStripePayengineGatewayEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsStripePayengineGatewayEnabledMutation';
import SuperAdminSettingsPage from 'modules/Organization/Settings/SuperAdmin/components/SuperAdminSettingsPage';

const SuperAdminPaymentsSettingsPageContent = ({settings, viewer}) => {
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const {handleSubmit} = useToggleSettingsIsStripePayengineGatewayEnabledMutation({
    settingsForm,
    onSuccess: () => {},
    onError: (errors) => console.log({errors}),
  });
  return (
    <SettingsSection>
      <SettingsSectionOption
        name={'Stripe Payments'}
        description={'Allows the company to connect a Stripe account to use on Supermove.'}
        isEnabled={settings.isStripePayengineGatewayEnabled}
        onPress={handleSubmit}
      />
    </SettingsSection>
  );
};

const SuperAdminPaymentsSettingsPage = () => {
  const {loading, data} = useQuery(SuperAdminPaymentsSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SuperAdminSettingsPage
      title={'Payments'}
      description={
        'Configure Payments and any integrated features. These changes will apply to the company, and if applicable, its branches.'
      }
      isLoading={loading}
      viewer={data?.viewer}
    >
      <SuperAdminPaymentsSettingsPageContent
        settings={data?.viewer.viewingOrganization.settings}
        viewer={data?.viewer}
      />
    </SuperAdminSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminPaymentsSettingsPage.query = gql`
  ${SettingsForm.edit.fragment}
  ${SuperAdminSettingsPage.fragment}

  query SuperAdminPaymentsSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        settings {
          id
          isStripePayengineGatewayEnabled
          ...SettingsForm_edit
        }
      }
      ...SuperAdminSettingsPage
    }
  }
`;

export default SuperAdminPaymentsSettingsPage;
