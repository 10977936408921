// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, Datetime, Currency} from '@supermove/utils';

import BillItem from './BillItem';
import CostItem from './CostItem';

const getRemainingBalance = withFragment(
  (compensationReport) => {
    const {total, paidOutAmount} = compensationReport;
    return total - paidOutAmount;
  },
  gql`
    fragment CompensationReport_getRemainingBalance on CompensationReport {
      id
      total
      paidOutAmount
    }
  `,
);

const constructCSVRowsForCompensationReport = withFragment(
  (compensationReport) => {
    // Initialized csv rows with headers
    const csvRows = [
      [
        ['Name'],
        ['Start Date'],
        ['End Date'],
        ['Compensation'],
        [],
        ['Item Name'],
        ['Quantity'],
        ['Price'],
        ['Total Price'],
        ['Rate'],
        ['Compensation'],
      ],
    ];
    const {fullName} = compensationReport.user;
    const {isEnabledTbdBillItems} = compensationReport.payrollReport.organization.features;
    const payrollStartDate = compensationReport.payrollReport.startDate
      ? Datetime.convertToDisplayDate(
          compensationReport.payrollReport.startDate,
          Datetime.DISPLAY_SHORT_DATE,
        )
      : '';
    const payrollEndDate = compensationReport.payrollReport.endDate
      ? Datetime.convertToDisplayDate(
          compensationReport.payrollReport.endDate,
          Datetime.DISPLAY_SHORT_DATE,
        )
      : '';
    const totalCompensation = Currency.display(compensationReport.total, {
      shouldHideCentsIfZero: true,
    });
    const csvHeader = [[fullName], [payrollStartDate], [payrollEndDate], [totalCompensation]];
    csvRows.push(csvHeader);
    // Push [] to start another row
    csvRows.push([]);
    const costs = _.orderBy(
      compensationReport.costs,
      ['project.startDate', 'project.endDate'],
      ['asc', 'asc'],
    );
    _.forEach(costs, (cost) => {
      const clientName = !_.isEmpty(cost.project.billingClient)
        ? cost.project.billingClient.name
        : cost.project.client.name;
      const projectStartDate = cost.project.startDate
        ? Datetime.convertToDisplayDate(cost.project.startDate, Datetime.DISPLAY_SHORT_DATE)
        : '';
      const projectEndDate = cost.project.endDate
        ? Datetime.convertToDisplayDate(cost.project.endDate, Datetime.DISPLAY_SHORT_DATE)
        : '';
      const projectCostTotal = Currency.display(cost.total, {shouldHideCentsIfZero: true});
      csvRows.push([
        [`Project ${cost.project.identifier}: ${clientName}`],
        [projectStartDate],
        [projectEndDate],
        [projectCostTotal],
      ]);
      _.forEach(cost.costItems, (costItem) => {
        const costItemQuantity = costItem.billItem ? costItem.billItem.minQuantity : '';
        const costItemPrice = costItem.billItem
          ? BillItem.getDisplayPrice(costItem.billItem, {isEnabledTbdBillItems}).replace(
              ' / hour',
              '',
            )
          : '';
        const costItemTotalPrice = costItem.billItem
          ? Currency.display(costItem.billItem.total, {shouldHideCentsIfZero: true})
          : CostItem.getDisplayRelatedTotal(costItem);
        const costItemRate = CostItem.getDisplayRate(costItem);
        const costItemTotal = Currency.display(costItem.total, {shouldHideCentsIfZero: true});
        csvRows.push([
          [],
          [],
          [],
          [],
          [],
          [costItem.name],
          [costItemQuantity],
          [costItemPrice],
          [costItemTotalPrice],
          [costItemRate],
          [costItemTotal],
        ]);
      });
      // Push [] to start another row
      csvRows.push([]);
    });

    return csvRows;
  },
  gql`
    ${CostItem.getDisplayRate.fragment}
    ${CostItem.getDisplayRelatedTotal.fragment}
    ${BillItem.getDisplayPrice.fragment}

    fragment CompensationReport_constructCSVRowsForCompensationReport on CompensationReport {
      id
      total
      payrollReport {
        id
        startDate
        endDate
        organization {
          id
          features {
            isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
          }
        }
      }
      costs {
        id
        total
        project {
          id
          startDate
          endDate
          identifier
          billingClient {
            id
            name
          }
          client {
            id
            name
          }
        }
        costItems {
          id
          name
          rate
          total
          relatedTotal
          billItem {
            id
            minQuantity
            amount
            total
            ...BillItem_getDisplayPrice
          }
          ...CostItem_getDisplayRate
          ...CostItem_getDisplayRelatedTotal
        }
      }
    }
  `,
);

const CompensationReport = {
  getRemainingBalance,
  constructCSVRowsForCompensationReport,
};

export default CompensationReport;
