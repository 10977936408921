// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Sheet from '@shared/design/components/Sheet';
import DayForm from '@shared/modules/Scheduling/forms/DayForm';
import useUpsertDayMutation from '@shared/modules/Scheduling/hooks/useUpsertDayMutation';
import AddDayNotesPopover from 'modules/App/Day/components/AddDayNotesPopover';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityCalendarDaySlotMetrics from 'modules/Calendar/Capacity/components/CapacityCalendarDaySlotMetrics';

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const DayNotesRow = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const DayDetailsMetricsContainer = Styled.View`
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const NotesContainer = Styled.View`
  flex: 1;
`;

const DayNotesSectionContainer = Styled.Touchable`
  flex: 1;
`;

const NotesContentContainer = Styled.View`
  flex: 1;
  padding: 12px;
  border-radius: 4px;
  flex-basis: auto;
  border: 1px solid ${colors.gray.border};
  background-color: ${({responsive}) => (responsive.desktop ? colors.gray.background : colors.white)};
`;

const DayNotesText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const DayNotesHeading = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.gray.secondary};
`;

const LastUpdatedText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const SheetBodyContainer = Styled.View`
  background-color: ${colors.gray.background};
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const DayNotesSection = ({
  index,
  day,
  organization,
  viewingOrganization,
  selectedDate,
  refetchDayView,
}) => {
  const responsive = useResponsive();
  const organizationId = organization.id;
  const addDayNotesPopover = usePopover();
  const dayForm = DayForm.upsert(day, {organizationId, value: selectedDate});
  const {form, handleSubmit, submitting} = useUpsertDayMutation({
    dayForm,
    onSuccess: () => {
      addDayNotesPopover.handleClose();
      refetchDayView();
    },
    onError: (errors) => console.log({errors}),
  });
  const isBlockEditForMainBranch = !viewingOrganization.isPrimary && organization.isPrimary;

  return (
    <Popover.Content innerRef={addDayNotesPopover.ref}>
      <DayNotesSectionContainer
        disabled={isBlockEditForMainBranch}
        onPress={addDayNotesPopover.handleOpen}
      >
        <DayNotesRow>
          <Icon source={Icon.StickyNote} color={colors.gray.secondary} size={12} />
          <Space width={4} />
          <DayNotesHeading>{organization.name}</DayNotesHeading>
          <Space style={{flex: 1}} />
          {!isBlockEditForMainBranch && (
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={12} />
          )}
        </DayNotesRow>
        <Space height={4} />
        {day && day.notes ? (
          <DayNotesText responsive={responsive}>{day.notes}</DayNotesText>
        ) : (
          <DayNotesText responsive={responsive} style={{color: colors.gray.tertiary}}>
            Click to add day notes.
          </DayNotesText>
        )}
        <Space height={4} />
        {day && (
          <LastUpdatedText>{`Last updated: ${Datetime.convertToDisplayDatetime(day.updatedAt)}${
            day.notesUpdatedBy ? ` by ${day.notesUpdatedBy.fullName}` : ''
          }`}</LastUpdatedText>
        )}
        <AddDayNotesPopover
          key={`${addDayNotesPopover.isOpen}_${index}`}
          popover={addDayNotesPopover}
          width={400}
          form={form}
          handleSubmit={handleSubmit}
          submitting={submitting}
          isClosableOutside
          offset={[270, 8]}
          organization={organization}
          isMultibranchDayView
        />
      </DayNotesSectionContainer>
    </Popover.Content>
  );
};

const DayMetricsAndNotesSheet = ({
  dayNotesSheet,
  selectedDateText,
  params,
  isLoading,
  viewingOrganization,
  capacityCalendarSingleDay,
  days,
  selectedDate,
  refetchDayView,
}) => {
  return (
    <Sheet
      isOpen={dayNotesSheet.isOpen}
      handleClose={dayNotesSheet.handleClose}
      headerText={selectedDateText}
      isFixedHeight={false}
    >
      <SheetBodyContainer>
        <DayMetricsAndNotes
          isLoading={isLoading}
          viewingOrganization={viewingOrganization}
          capacityCalendarSingleDay={capacityCalendarSingleDay}
          days={days}
          selectedDate={selectedDate}
          params={params}
          refetchDayView={refetchDayView}
        />
      </SheetBodyContainer>
    </Sheet>
  );
};

const DayMetricsAndNotes = ({
  isLoading,
  viewingOrganization,
  capacityCalendarSingleDay,
  days,
  selectedDate,
  params,
  refetchDayView,
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <DayDetailsMetricsContainer>
        {isLoading ? (
          <CapacityCalendarDaySlotMetrics.SkeletonLoader />
        ) : (
          <CapacityCalendarDaySlotMetrics
            capacityCalendar={capacityCalendarSingleDay}
            cellDate={selectedDate}
            shouldHideDayNotes
          />
        )}
      </DayDetailsMetricsContainer>
      <Space height={16} />
      <Row>
        {isLoading || !params.slugs || !viewingOrganization ? (
          <NotesContentContainer responsive={responsive}>
            <SkeletonLoader height={72} isFullWidth />
          </NotesContentContainer>
        ) : (
          <NotesContainer>
            {
              // We need to loop through each org instead of days because a day object is not always created
              // For Franchise/Branch orgs, we will filter it out
              Organization.getOrganizationBySlugsWithParent({
                slugs: params.slugs,
                organization: viewingOrganization,
              }).map((organization, index) => {
                const organizationDay = days.find((day) => day.organization.id === organization.id);
                return (
                  <React.Fragment key={index}>
                    <NotesContentContainer responsive={responsive}>
                      <DayNotesSection
                        index={index}
                        refetchDayView={refetchDayView}
                        day={organizationDay}
                        organization={organization}
                        viewingOrganization={viewingOrganization}
                        selectedDate={selectedDate}
                      />
                      <Space width={8} />
                    </NotesContentContainer>
                    <Space height={12} />
                  </React.Fragment>
                );
              })
            }
          </NotesContainer>
        )}
      </Row>
      <Space height={48} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayMetricsAndNotes.fragment = gql`
  ${AddDayNotesPopover.fragment}
  ${CapacityCalendarDaySlotMetrics.fragment}
  ${DayForm.edit.fragment}
  ${Organization.getOrganizationBySlugsWithParent.fragment}

  fragment DayMetricsAndNotes_Organization on Organization {
    id
    isPrimary
    company {
      id
      organizations {
        id
        ...AddDayNotesPopover
      }
    }
    ...Organization_getOrganizationBySlugsWithParent
  }

  fragment DayMetricsAndNotes_CapacityCalendar on CapacityCalendar {
    ...CapacityCalendarDaySlotMetrics
  }

  fragment DayMetricsAndNotes_Day on Day {
    id
    organization {
      id
    }
    updatedAt
    notesUpdatedBy {
      id
      fullName
    }
    ...DayForm_edit
  }
`;

DayMetricsAndNotes.Sheet = DayMetricsAndNotesSheet;

export default DayMetricsAndNotes;
