// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useHover,
  usePopover,
  useResponsive,
  useState,
  useExportRowsToCsv,
} from '@supermove/hooks';
import {CompensationReport, PayrollReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import CompensationReportStatus from '@shared/modules/Accounting/enums/CompensationReportStatus';
import CompensationReportForm from '@shared/modules/Accounting/forms/CompensationReportForm';
import useUpdateCompensationReportMutation from '@shared/modules/Accounting/hooks/useUpdateCompensationReportMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import CompensationReportActions from 'modules/Accounting/CompensationReport/components/CompensationReportActions';
import CompensationReportsDrawer from 'modules/Accounting/CompensationReport/components/CompensationReportsDrawer';

const Column = Styled.View`
  flex: 1;
`;

const Button = Styled.ButtonV2`
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const StatusOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  margin-vertical: 1px;
`;

const StatusOptionText = Styled.Text`
  ${Typography.Body4}
  padding-vertical: 4px;
  padding-horizontal: 8px;
  color: ${colors.blue.interactive};
`;

const StatusButton = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  width: ${({mobile}) => (mobile ? '76px' : '104px')};
  background-color: ${({isPaidOut}) => (isPaidOut ? colors.green.accent : colors.gray.background)};
  border-color: ${({borderColor}) => borderColor};
  border-width: 1px;
  border-radius: 4px;
`;

const StatusButtonText = Styled.Text`
  ${Typography.Label4};
  color: ${({color}) => color};
`;

const StatusMenuPopoverContainer = Styled.View`
  width: 104px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const getStatusBorderColor = ({status, isActive}) => {
  if (status === CompensationReportStatus.PAID_OUT) {
    if (isActive) {
      return colors.green.status;
    }
    return colors.green.accent;
  }
  if (isActive) {
    return colors.gray.border;
  }
  return colors.gray.background;
};

const handleUpdateCompensationReportStatus = ({form, field, handleSubmit, status}) => {
  form.setFieldValue(`${field}.status`, status);
  setTimeout(() => handleSubmit(), 0);
};

const StatusOption = ({form, field, handleSubmit, status}) => {
  const {isHovered, ref} = useHover();

  return (
    <StatusOptionButton
      onPress={() => handleUpdateCompensationReportStatus({form, field, handleSubmit, status})}
      isHovered={isHovered}
      ref={ref}
    >
      <StatusOptionText>{CompensationReportStatus.getDisplayStatus(status)}</StatusOptionText>
    </StatusOptionButton>
  );
};

const StatusMenuPopover = ({popover, form, field, handleSubmit}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <StatusMenuPopoverContainer>
        <Space height={7} />
        {CompensationReportStatus.VALUES.map((status) => {
          return (
            <StatusOption
              key={status}
              form={form}
              field={field}
              handleSubmit={handleSubmit}
              status={status}
            />
          );
        })}
        <Space height={7} />
      </StatusMenuPopoverContainer>
    </Popover>
  );
};

const StatusSelector = ({compensationReport, viewerRole, refetch}) => {
  const statusPopover = usePopover();

  const compensationReportForm = CompensationReportForm.edit(compensationReport);
  const {form, handleSubmit} = useUpdateCompensationReportMutation({
    compensationReportForm,
    onSuccess: refetch,
    onError: (errors) => {
      console.log({errors});
      refetch();
    },
  });

  const field = 'compensationReportForm';
  const responsive = useResponsive();
  const {ref, isHovered} = useHover();
  const disabled = !UserRole.getIsStaffAdmin(viewerRole);
  const isActive = (isHovered || statusPopover.isOpen) && !disabled;
  const {status} = compensationReport;
  const isPaidOut = status === CompensationReportStatus.PAID_OUT;

  return (
    <React.Fragment>
      <Popover.Content innerRef={statusPopover.ref}>
        <StatusButton
          onPress={statusPopover.handleToggle}
          ref={ref}
          isActive={isActive}
          isPaidOut={isPaidOut}
          borderColor={getStatusBorderColor({status, isActive})}
          disabled={disabled}
          {...responsive}
        >
          <StatusButtonText color={isPaidOut ? colors.green.status : colors.gray.tertiary}>
            {isPaidOut ? 'Paid Out' : 'Not Paid Out'}
          </StatusButtonText>
          <Space style={{flex: 1}} />
          {!disabled && (
            <Icon
              source={Icon.CaretDown}
              size={13}
              color={isPaidOut ? colors.green.status : colors.gray.tertiary}
            />
          )}
        </StatusButton>
      </Popover.Content>
      <StatusMenuPopover
        popover={statusPopover}
        form={form}
        field={field}
        handleSubmit={() => {
          handleSubmit();
          statusPopover.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({
  handleViewReport,
  organizationSlug,
  viewerRole,
  isCostAndCompensationEnabled,
  isEnabledCompensationCsvDownload,
  handleExport,
  mobile,
  refetch,
}) => [
  {
    flex: 2,
    isHidden: UserRole.getIsStaffAdmin(viewerRole),
    headerContent: () => {
      return <Table.HeaderText>Pay Period</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      return (
        <CellText>
          {PayrollReport.getDateRange(compensationReport.payrollReport, {isShortened: true})}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    isHidden: !UserRole.getIsStaffAdmin(viewerRole) || mobile,
    headerContent: () => {
      return <Table.HeaderText>Report Number</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport, rowIndex}) => {
      return isEnabledCompensationCsvDownload ? (
        <Button onPress={() => handleViewReport({index: rowIndex})}>
          <LinkText>{compensationReport.identifier}</LinkText>
        </Button>
      ) : (
        <CellText>{compensationReport.identifier}</CellText>
      );
    },
  },
  {
    flex: 2,
    isHidden: !UserRole.getIsStaffAdmin(viewerRole),
    headerContent: () => {
      return <Table.HeaderText>Employee</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      return <CellText>{compensationReport.user.fullName}</CellText>;
    },
  },
  {
    flex: 2,
    isHidden: mobile,
    headerContent: () => {
      return <Table.HeaderText>Position</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      return <CellText>{compensationReport.user.position}</CellText>;
    },
  },
  {
    flex: 2,
    isHidden: mobile,
    headerContent: () => {
      return <Table.HeaderText>Projects</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      return <CellText>{compensationReport.numberOfProjects}</CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText numberOfLines={1}>Compensation</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      const {total} = compensationReport;
      return <CellText>{Currency.display(total, {shouldHideCentsIfZero: total === 0})}</CellText>;
    },
  },
  {
    flex: 2,
    isHidden: mobile,
    headerContent: () => {
      return (
        <Column>
          <Table.HeaderText numberOfLines={1}>Paid</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>{`Remaining${
            mobile ? '' : ' Bal.'
          }`}</Table.HeaderSubtext>
        </Column>
      );
    },
    cellContent: ({item: compensationReport}) => {
      const paid = compensationReport.paidOutAmount;
      const balance = CompensationReport.getRemainingBalance(compensationReport);
      return (
        <Column>
          <CellText>{Currency.display(paid, {shouldHideCentsIfZero: paid === 0})}</CellText>
          <CellText style={{color: balance > 0 ? colors.red.warning : colors.gray.secondary}}>
            {Currency.display(balance, {shouldHideCentsIfZero: balance === 0})}
          </CellText>
        </Column>
      );
    },
  },
  {
    flex: 2,
    isHidden: !UserRole.hasOfficePermissions(viewerRole),
    headerContent: () => {
      return <Table.HeaderText>Status</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      return (
        <StatusSelector
          compensationReport={compensationReport}
          viewerRole={viewerRole}
          refetch={refetch}
        />
      );
    },
  },
  {
    flex: 1,
    isHidden: !isCostAndCompensationEnabled || isEnabledCompensationCsvDownload || mobile,
    headerContent: () => {
      return <Table.HeaderText>PDF</Table.HeaderText>;
    },
    cellContent: ({item: compensationReport}) => {
      const disabled = compensationReport.status === CompensationReportStatus.NOT_PAID_OUT;
      return (
        <a
          href={`/0/${organizationSlug}/compensation-reports/${compensationReport.uuid}/print`}
          target={'_blank'}
          style={{padding: 4, pointerEvents: disabled ? 'none' : null}}
        >
          <Icon
            source={Icon.FileDownload}
            size={14}
            color={disabled ? colors.gray.tertiary : colors.blue.interactive}
          />
        </a>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Action</Table.HeaderText>;
    },
    cellContent: ({rowIndex, item: compensationReport}) => {
      return isEnabledCompensationCsvDownload ? (
        <ReportActions
          rowIndex={rowIndex}
          organizationSlug={organizationSlug}
          compensationReport={compensationReport}
          handleExport={handleExport}
        />
      ) : (
        <Button onPress={() => handleViewReport({index: rowIndex})}>
          <ButtonText>View</ButtonText>
        </Button>
      );
    },
  },
];

const ReportActions = ({rowIndex, compensationReport, organizationSlug, handleExport}) => {
  const compensationReportActionsPopover = usePopover();
  return (
    <CompensationReportActions
      key={`${rowIndex}_${compensationReport.id}`}
      organizationSlug={organizationSlug}
      compensationReport={compensationReport}
      handleExport={handleExport}
      compensationReportActionsPopover={compensationReportActionsPopover}
    />
  );
};

const CompensationReportsListV2 = ({
  compensationReports,
  organizationSlug,
  refetch,
  viewerRole,
  isCostAndCompensationEnabled,
  isEnabledCompensationCsvDownload,
}) => {
  const responsive = useResponsive();
  const compensationReportsDrawer = useDrawer({
    name: 'Compensation Reports Drawer',
    enableTracking: true,
  });
  const [viewingCompensationReportIndex, setViewingCompensationReportIndex] = useState(0);
  const handleViewReport = ({index}) => {
    setViewingCompensationReportIndex(index);
    compensationReportsDrawer.handleOpen();
  };
  const {handleExport} = useExportRowsToCsv();

  return (
    <React.Fragment>
      <Table.FixedHeaderScroll
        columnDefinitions={getColumnDefinitions({
          organizationSlug,
          viewerRole,
          isCostAndCompensationEnabled,
          isEnabledCompensationCsvDownload,
          handleViewReport,
          handleExport,
          refetch,
          ...responsive,
        })}
        items={compensationReports}
        emptyStateText={'There are no compensation reports for this pay period'}
      />
      <CompensationReportsDrawer
        isOpen={compensationReportsDrawer.isOpen}
        handleClose={compensationReportsDrawer.handleClose}
        viewingCompensationReportIndex={viewingCompensationReportIndex}
        setViewingCompensationReportIndex={setViewingCompensationReportIndex}
        compensationReports={compensationReports}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompensationReportsListV2.fragment = gql`
  ${CompensationReportForm.edit.fragment}
  ${CompensationReportsDrawer.fragment}
  ${PayrollReport.getDateRange.fragment}
  ${CompensationReport.getRemainingBalance.fragment}

  fragment CompensationReportsListV2 on CompensationReport {
    id
    uuid
    identifier
    status
    total
    numberOfProjects
    paidOutAmount
    user {
      id
      fullName
      position
    }
    payrollReport {
      id
      ...PayrollReport_getDateRange
    }
    ...CompensationReportForm_edit
    ...CompensationReportsDrawer
    ...CompensationReport_getRemainingBalance
  }
`;

export default CompensationReportsListV2;
