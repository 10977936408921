// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, useEffect} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import BillingValuesFields from 'modules/Project/Billing/components/BillingValuesFields';

const StorageBillingValuesField = ({
  form,
  field,
  isEdit,
  projectTypeUuid,
}: {
  form: any;
  field: string;
  isEdit: boolean;
  projectTypeUuid: string;
}) => {
  const {data} = useQuery(StorageBillingValuesField.query, {
    fetchPolicy: 'network-only',
    variables: {
      projectTypeUuid,
    },
  });

  useEffect(() => {
    if (data && data.projectTypeByUuid) {
      const projectType = data.projectTypeByUuid;
      form.setFieldValue(
        `${field}.valueForms`,
        Organization.makeProjectValueFormsFromProjectTypeVariableSections({
          projectType,
        }),
      );
    }
  }, [data, field, form]);

  return <BillingValuesFields form={form} field={field} isEdit={isEdit} isShowTitle={false} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageBillingValuesField.query = gql`
  ${Organization.makeProjectValueFormsFromProjectTypeVariableSections.fragment}

  query StorageBillingValuesField($projectTypeUuid: String!) {
    ${gql.query}
    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      ...Organization_makeProjectValueFormsFromProjectTypeVariableSections
    }
  }
`;

export default StorageBillingValuesField;
