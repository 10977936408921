import {gql} from '@supermove/graphql';
import {BillItem} from '@supermove/models';
import {Currency, Percent, withFragment, Datetime} from '@supermove/utils';

import Expression from '@shared/ast/Expression';
import FormulaPrettyPrinter from '@shared/formulas/src/FormulaPrettyPrinter';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import LineItemCategory from '@shared/modules/Billing/enums/LineItemCategory';
import LineItemKind from '@shared/modules/Billing/enums/LineItemKind';

const getDisplayBillStage = withFragment(
  (billItemType) => {
    if (billItemType.billStage === BillStage.POST_SUBTOTAL) {
      return 'After Subtotal';
    }
    return 'Before Subtotal';
  },
  gql`
    fragment BillItemType_getDisplayBillStage on BillItemType {
      id
      billStage
    }
  `,
);

const getDisplayValue = withFragment(
  (billItemType) => {
    if (billItemType.category === BillItemTypeCategory.SUPPLIES) {
      return billItemType.amount === null ? 'TBD' : Currency.display(billItemType.amount);
    }
    if (billItemType.kind === BillItemTypeKind.AMOUNT) {
      if (billItemType.amount === null) {
        return 'TBD';
      }
      const amount = Currency.display(billItemType.amount);
      if (billItemType.unit === BillItemUnit.HOUR) {
        return `${amount} / hour`;
      }
      return amount;
    }
    return billItemType.percentage === null ? 'TBD' : Percent.display(billItemType.percentage);
  },
  gql`
    fragment BillItemType_getDisplayValue on BillItemType {
      id
      amount
      percentage
      category
      kind
      unit
    }
  `,
);

const getDisplayQuantity = withFragment(
  (billItemType, {isEnabledTbdBillItems}) => {
    if (billItemType.billStage === BillStage.POST_SUBTOTAL && isEnabledTbdBillItems) {
      return 'TBD';
    }
    return BillItem.getEstimateQuantity(
      {
        minQuantity: billItemType.minQuantity,
        maxQuantity: billItemType.maxQuantity,
        unit: billItemType.unit,
        kind: billItemType.kind,
      },
      {isEnabledTbdBillItems},
    );
  },
  gql`
    fragment BillItemType_getDisplayQuantity on BillItemType {
      id
      minQuantity
      maxQuantity
      unit
      kind
      billStage
    }
  `,
);

const getDisplayName = withFragment(
  (billItemType) => {
    const moverPositionName = billItemType.moverPosition
      ? ` (${billItemType.moverPosition.name})`
      : '';
    return `${billItemType.name}${moverPositionName}`;
  },
  gql`
    fragment BillItemType_getDisplayName on BillItemType {
      id
      name
      moverPosition {
        id
        name
      }
    }
  `,
);

const getIsSynced = withFragment(
  (billItemType) => {
    return !!billItemType.identifier && !billItemType.createdById;
  },
  gql`
    fragment BillItemType_getIsSynced on BillItemType {
      id
      identifier
      createdById
    }
  `,
);

const hasAmountFormula = withFragment(
  (billItemType) => {
    return !!billItemType.amountFormulaId;
  },
  gql`
    fragment BillItemType_hasAmountFormula on BillItemType {
      id
      amountFormulaId
    }
  `,
);

const hasNameFormula = withFragment(
  (billItemType) => {
    return !!billItemType.nameFormulaId;
  },
  gql`
    fragment BillItemType_hasNameFormula on BillItemType {
      id
      nameFormulaId
    }
  `,
);

const hasQuantityFormula = withFragment(
  (billItemType) => {
    return !!billItemType.minQuantityFormulaId || !!billItemType.maxQuantityFormulaId;
  },
  gql`
    fragment BillItemType_hasQuantityFormula on BillItemType {
      id
      minQuantityFormulaId
      maxQuantityFormulaId
    }
  `,
);

const renderNameFormulaString = withFragment(
  (billItemType) => {
    if (billItemType.nameFormula && billItemType.nameFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse(billItemType.nameFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillItemType_renderNameFormulaString on BillItemType {
      id
      nameFormula {
        id
        astJson
      }
    }
  `,
);

const renderAmountFormulaString = withFragment(
  (billItemType) => {
    if (billItemType.amountFormula && billItemType.amountFormula.astJson) {
      return new FormulaPrettyPrinter().print(
        Expression.fromJSON(JSON.parse(billItemType.amountFormula.astJson)),
      );
    }
  },
  gql`
    fragment BillItemType_renderAmountFormulaString on BillItemType {
      id
      amountFormula {
        id
        astJson
      }
    }
  `,
);

const getLineItemCategoryFromBillItemTypeCategory = (billItemTypeCategory) => {
  const translationMap = {
    [BillItemTypeCategory.FEES]: LineItemCategory.EXTRA_CHARGE,
    [BillItemTypeCategory.DISCOUNTS]: LineItemCategory.EXTRA_DISCOUNT,
    [BillItemTypeCategory.SUPPLIES]: LineItemCategory.SUPPLIES,
  };
  return translationMap[billItemTypeCategory];
};

const getLineItemKindFromBillItemType = ({category, unit}) => {
  const translationMap = {
    [BillItemTypeCategory.FEES]:
      unit === BillItemUnit.HOUR ? LineItemKind.HOURLY : LineItemKind.FLAT_RATE,
    [BillItemTypeCategory.SUPPLIES]: LineItemKind.SUPPLIES,
    [BillItemTypeCategory.DISCOUNTS]: LineItemKind.DISCOUNT,
  };
  return translationMap[category];
};

const getBillModifierTypesFromBillItemTypes = withFragment(
  (billItemTypes) => {
    return billItemTypes
      .filter(({billStage}) => billStage === BillStage.POST_SUBTOTAL)
      .map(
        ({
          amount,
          description,
          identifier,
          kind,
          name,
          percentage,
          organizationId,
          nameFormulaId,
          amountFormulaId,
          id,
        }) => ({
          amount,
          description,
          identifier,
          kind,
          name,
          percentage,
          organizationId,
          billItemTypeId: id,
          nameFormulaId,
          amountFormulaId,
        }),
      );
  },
  gql`
    fragment BillItemType_getBillModifierTypesFromBillItemTypes on BillItemType {
      id
      amount
      billStage
      description
      identifier
      kind
      name
      percentage
      organizationId
      nameFormulaId
      amountFormulaId
    }
  `,
);

const getTemplateLineItemsFromBillItemTypes = withFragment(
  (billItemTypes) => {
    return billItemTypes
      .filter(({billStage}) => billStage === BillStage.PRE_SUBTOTAL)
      .map(
        ({
          id,
          category,
          description,
          identifier,
          name,
          amount,
          minQuantity,
          maxQuantity,
          unit,
          nameFormulaId,
          amountFormulaId,
          minQuantityFormulaId,
          maxQuantityFormulaId,
        }) => ({
          category: getLineItemCategoryFromBillItemTypeCategory(category),
          description,
          identifier,
          kind: getLineItemKindFromBillItemType({category, unit}),
          name,
          price: amount,
          minQuantity,
          maxQuantity,
          unit,
          billItemTypeId: id,
          nameFormulaId,
          priceFormulaId: amountFormulaId,
          minQuantityFormulaId,
          maxQuantityFormulaId,
        }),
      );
  },
  gql`
    fragment BillItemType_getTemplateLineItemsFromBillItemTypes on BillItemType {
      id
      amountFormulaId
      maxQuantityFormulaId
      minQuantityFormulaId
      nameFormulaId
      amount
      billStage
      category
      description
      identifier
      maxQuantity
      minQuantity
      name
      unit
    }
  `,
);

const getDisplayUpdatedAt = withFragment(
  (billItemType) => {
    return Datetime.convertToDisplayDatetime(billItemType.updatedAt);
  },
  gql`
    fragment BillItemType_getDisplayUpdatedAt on BillItemType {
      id
      updatedAt
    }
  `,
);

const BillItemType = {
  getDisplayBillStage,
  getDisplayValue,
  getDisplayQuantity,
  getDisplayUpdatedAt,
  getDisplayName,
  getIsSynced,
  hasAmountFormula,
  hasNameFormula,
  hasQuantityFormula,
  getBillModifierTypesFromBillItemTypes,
  getTemplateLineItemsFromBillItemTypes,
  renderNameFormulaString,
  renderAmountFormulaString,
};

export default BillItemType;
