// Libraries
import _ from 'lodash';

// Supermove
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

const URL_TO_VARIABLE_MAP = {
  all: 'ALL',
  moves: 'MOVE',
  storage: 'STORAGE',
};

const ALL_CATEGORIES = [ProjectTypeCategory.MOVE, ProjectTypeCategory.STORAGE];

const AVAILABLE_FILTERS = ['salespersonIds', 'slugs'];

const _new = () => ({
  projectCategories: null,
  salespersonIds: null,
  searchQuery: null,
  sortSettings: null,
  slugs: null,

  // Private
  category: null,
});

const toForm = ({
  projectCategories,
  salespersonIds,
  searchQuery,
  sortSettings,
  category,
  slugs,
}) => ({
  projectCategories,
  salespersonIds,
  searchQuery,
  sortSettings,
  slugs,

  // Private
  category,
});

const toQueryParams = ({
  projectCategories,
  salespersonIds,
  searchQuery,
  sortSettings,
  category,
  slugs,
}) => ({
  projectCategories,
  salespersonIds,
  searchQuery,
  sortSettings,
  slugs,

  // Private
  category,
});

const getNumberOfFiltersApplied = ({form, organization, isRestricted}) =>
  AVAILABLE_FILTERS.reduce((appliedFiltersCount, filter) => {
    const formFilter = form.values.filteredClientsForm[filter];
    // Do not include empty arrays in applied filters count
    if (
      formFilter &&
      formFilter.length !== 0 &&
      !(filter === 'slugs' && _.isEqual(formFilter, ['ALL_ORGANIZATIONS'])) &&
      organization?.canViewOtherBranchesData &&
      !(filter === 'salespersonIds' && isRestricted)
    ) {
      return appliedFiltersCount + 1;
    } else {
      return appliedFiltersCount;
    }
  }, 0);

const FilteredClientsForm = {
  new: _new,
  toForm,
  toQueryParams,
  getNumberOfFiltersApplied,
  URL_TO_VARIABLE_MAP,
  ALL_CATEGORIES,
};

export default FilteredClientsForm;
