export interface ProjectTypesFormType {
  projectTypeIds: string[];
  values?: any;
}

const _new = () => ({
  projectTypeIds: [],
});

const toForm = ({projectTypeIds}: ProjectTypesFormType) => ({
  projectTypeIds,
});

const toMutation = ({projectTypeIds}: ProjectTypesFormType) => ({
  projectTypeIds,
});

const ProjectTypesForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ProjectTypesForm;
