// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation, useToast} from '@supermove/hooks';

// App
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import ReorderReportDashboardsForm from '@shared/modules/Reports/forms/ReorderReportDashboardsForm';

const useReorderReportDashboardsMutation = ({reorderReportDashboardsForm, onSuccess, onError}) => {
  const reorderErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'An error occurred while reordering the dashboard.',
  });

  const form = useForm({
    initialValues: {
      reorderReportDashboardsForm: ReorderReportDashboardsForm.toForm(reorderReportDashboardsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderReportDashboardsMutation.mutation,
    variables: {
      reorderReportDashboardsForm: ReorderReportDashboardsForm.toMutation(
        form.values.reorderReportDashboardsForm,
      ),
    },
    onSuccess,
    onError: (errors) => {
      reorderErrorToast.handleToast();
      onError(errors);
    },
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderReportDashboardsMutation.mutation = gql`
  mutation useReorderReportDashboardsMutation($reorderReportDashboardsForm: ReorderReportDashboardsForm!) {
    response: reorderReportDashboards(reorderReportDashboardsForm: $reorderReportDashboardsForm) {
      ${gql.errors}
      dashboards {
        id
      }
    }
  }
`;

export default useReorderReportDashboardsMutation;
