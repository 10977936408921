// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput, ScrollView, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';

const Row = Styled.View`
  flex-direction: row;
`;

const FileDragInputContainer = Styled.View`
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-style: dotted;
  border-width: 2px;
  cursor: pointer;
  border-color: ${colors.gray.tertiary};
  background-color: ${colors.gray.background};
  padding: 12px;
`;

const FileDragInputText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Label}
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const FileTypesText = Styled.Text`
  ${Typography.Micro}
`;

const LoadingText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const FilenameText = Styled.Text`
  ${Typography.Body}
  color: ${colors.blue.interactive};
  text-align: center;
`;

const ValidationContainer = Styled.View`
  justify-content: start;
`;

const ValidationErrorText = Styled.Text`
  ${Typography.Body}
  color: ${colors.red.warning};
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const ValidationErrors = ({form, field, validationErrors}) => {
  return (
    <React.Fragment>
      <Space height={16} />
      <ScrollView>
        {!_.isEmpty(validationErrors) && (
          <React.Fragment>
            <ValidationContainer>
              <ValidationErrorText>
                {`Error: This file could not be uploaded. ` +
                  `Please review the following ${pluralize(
                    'error',
                    _.size(validationErrors),
                    true,
                  )}:`}
              </ValidationErrorText>
              <Space height={8} />
              {validationErrors.map((value) => {
                return (
                  <Row>
                    <Space width={8} />
                    <ValidationErrorText>•</ValidationErrorText>
                    <Space width={8} />
                    <ValidationErrorText>{value}</ValidationErrorText>
                  </Row>
                );
              })}
            </ValidationContainer>
          </React.Fragment>
        )}
        <ValidationError>
          {_.get(form.errors, `${field}.requestUploadFileForm.mimetype`)}
        </ValidationError>
        <ValidationError>
          {_.get(form.errors, `${field}.requestUploadFileForm.filename`)}
        </ValidationError>
        <ValidationError>{_.get(form.errors, `${field}.file`)}</ValidationError>
      </ScrollView>
    </React.Fragment>
  );
};

const FileInputFieldV2 = ({
  field,
  form,
  submitting,
  handleSubmit,
  validationErrors,
  supportedFileTypes,
  hideDragInput,
  style,
}) => {
  const file = _.get(form, `values.${field}.file`);
  const hasErrors = (file && !_.isEmpty(form.errors)) || !_.isEmpty(validationErrors);
  return (
    <React.Fragment>
      <FileDragInput
        onFilesChange={(files) => {
          const file = files[0];
          form.setFieldValue(`${field}.requestUploadFileForm.mimetype`, file.type);
          form.setFieldValue(
            `${field}.requestUploadFileForm.filename`,
            UploadFileForm.formatName(file.name),
          );
          form.setFieldValue(`${field}.file`, file);

          form.setSubmitting(true);
          handleSubmit();
        }}
      >
        {({isDragActive}) => (
          <FileDragInputContainer style={style}>
            {file ? (
              <React.Fragment>
                {submitting ? (
                  <LoadingText>Loading...</LoadingText>
                ) : (
                  <FilenameText>{file.name}</FilenameText>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FileDragInputText>
                  {isDragActive ? (
                    'Drop the file here'
                  ) : (
                    <React.Fragment>
                      {hideDragInput ? '' : `Drag a file or `}
                      <LinkText>{`${hideDragInput ? 'C' : 'c'}lick to upload`}</LinkText>
                    </React.Fragment>
                  )}
                </FileDragInputText>
                {!_.isEmpty(supportedFileTypes) && (
                  <React.Fragment>
                    <Space height={8} />
                    <FileTypesText>{_.join(supportedFileTypes, ', ')}</FileTypesText>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </FileDragInputContainer>
        )}
      </FileDragInput>
      {hasErrors && (
        <ValidationErrors form={form} field={field} validationErrors={validationErrors} />
      )}
    </React.Fragment>
  );
};

FileInputFieldV2.resetUploadFileForm = ({field, form}) => {
  form.setFieldValue(`${field}.requestUploadFileForm.mimetype`, null);
  form.setFieldValue(`${field}.requestUploadFileForm.filename`, null);
  form.setFieldValue(`${field}.file`, null);
};

export default FileInputFieldV2;
