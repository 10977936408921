// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove

// App
import useAppContext from 'modules/App/context/useAppContext';
import RootDashboardPage from 'modules/Report/Root/RootDashboardPage';

const ReportsPage = () => {
  const {viewer} = useAppContext();

  const isEnabledOfficeGlobalReports = _.get(
    viewer,
    'viewingOrganization.features.isEnabledOfficeGlobalReports',
    false,
  );

  if (!isEnabledOfficeGlobalReports) {
    return <RootDashboardPage />;
  }

  return <div>ReportsPage</div>;
};

export default ReportsPage;
