// Libraries
import React from 'react';

// App
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

const Title = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

interface SettingsSectionProps {
  title?: string;
  children: React.ReactNode;
}

// Until we update React >= 18 and Typescript >= 5.1, there's a slight preferance to type the props directly over using React.FC
// See PR #25818 for details. Takeaway here is to explicitely define the children props
const SettingsSection: React.FC<SettingsSectionProps> = ({title = '', children}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {!!title && (
        <React.Fragment>
          <Title responsive={responsive}>{title}</Title>
          <Space height={8} />
        </React.Fragment>
      )}
      {children}
    </React.Fragment>
  );
};

export default SettingsSection;
