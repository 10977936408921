// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import BillingDocumentBillPostSubtotalBillItems from '@shared/modules/Document/Billing/components/BillingDocumentBillPostSubtotalBillItems';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const LineItemName = Styled.Text`
  ${Typography.Label2};
  flex: 4;
`;

const LineItemDetailContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const LineItemDetailText = Styled.Text`
  ${Typography.Body3};
  text-align: right;
  `;

const MobileLineItemText = Styled.Text`
  ${Typography.Body3};
  color: ${(props) => props.color};
`;

const AlignRightColumn = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const LineItemDetail = ({icon, children, flex}) => {
  return (
    <LineItemDetailContainer flex={flex}>
      <Row>
        {icon}
        <LineItemDetailText>{children}</LineItemDetailText>
      </Row>
    </LineItemDetailContainer>
  );
};

const BillingDocumentBillSubtotalSection = ({bill}) => {
  const responsive = useResponsive();
  const {estimateSubtotalMin: min, estimateSubtotalMax: max} = bill;
  const subtotal = bill.isSubtotalAvailable ? Currency.formatRange({min, max}) : 'TBD';
  return (
    <React.Fragment>
      {responsive.mobile ? (
        <SplitRow>
          <BillTotalText color={colors.gray.primary}>Subtotal</BillTotalText>
          <BillTotalText color={colors.gray.primary}>{subtotal}</BillTotalText>
        </SplitRow>
      ) : (
        <Row>
          <AlignRightColumn>
            <BillTotalText color={colors.gray.primary}>Subtotal</BillTotalText>
          </AlignRightColumn>
          <LineItemDetail flex={3}>
            <BillTotalText color={colors.gray.primary}>{subtotal}</BillTotalText>
          </LineItemDetail>
        </Row>
      )}
      <BillingDocumentBillPostSubtotalBillItems bill={bill} />
      {/* With project billing, all tips are on the primary bill for the project and should
      show as a group of tips above the grand total. However some old bills may have tips on
      job bills, so we show them here. */}
      {bill.tip > 0 && (
        <React.Fragment>
          {responsive.mobile ? (
            <SplitRow>
              <MobileLineItemText color={colors.gray.primary}>Tip</MobileLineItemText>
              <MobileLineItemText color={colors.gray.primary}>
                {Currency.format({value: bill.tip})}
              </MobileLineItemText>
            </SplitRow>
          ) : (
            <React.Fragment>
              <Space height={4} />
              <Row>
                <AlignRightColumn>
                  <LineItemName>Tip</LineItemName>
                </AlignRightColumn>
                <LineItemDetail flex={3}>{Currency.format({value: bill.tip})}</LineItemDetail>
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {(bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0) && (
        <React.Fragment>
          <Space height={4} />
          {responsive.mobile ? (
            <SplitRow>
              <MobileLineItemText color={colors.gray.primary}>{`Sales Tax (${Percent.display(
                bill.project.salesTaxRate,
              )})`}</MobileLineItemText>
              <MobileLineItemText color={colors.gray.primary}>
                {Currency.formatRange({
                  min: bill.estimateSalesTaxAmountMin,
                  max: bill.estimateSalesTaxAmountMax,
                })}
              </MobileLineItemText>
            </SplitRow>
          ) : (
            <Row>
              <AlignRightColumn>
                <LineItemName>{`Sales Tax (${Percent.display(
                  bill.project.salesTaxRate,
                )})`}</LineItemName>
              </AlignRightColumn>
              <LineItemDetail flex={3}>
                {Currency.formatRange({
                  min: bill.estimateSalesTaxAmountMin,
                  max: bill.estimateSalesTaxAmountMax,
                })}
              </LineItemDetail>
            </Row>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillingDocumentBillSubtotalSection.fragment = gql`
  ${BillingDocumentBillPostSubtotalBillItems.fragment}

  fragment BillingDocumentBillSubtotalSection on Bill {
    id
    isSubtotalAvailable
    estimateSubtotalMin
    estimateSubtotalMax
    tip
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    ...BillingDocumentBillPostSubtotalBillItems
    project {
      id
      salesTaxRate
    }
  }
`;

export default BillingDocumentBillSubtotalSection;
