// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useResponsive, useState} from '@supermove/hooks';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import FinalizedInvoiceCallout from 'modules/Project/V2/Show/Blocks/components/FinalizedInvoiceCallout';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import BillingActionButtons from 'modules/Project/V2/Show/components/BillingActionButtons';
import ProjectBills from 'modules/Project/V2/Show/components/ProjectBills';
import ProjectHeader from 'modules/Project/V2/Show/components/ProjectHeader';

const BLOCK_TITLE = 'Billing';

const SkeletonBody = () => {
  return (
    <React.Fragment>
      <SkeletonLoader isFullWidth height={36} />
      <Space height={16} />
      <ProjectBills.SkeletonLoader />
    </React.Fragment>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={BillingActionButtons.SkeletonLoader}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const BodyComponent = ({
  project,
  refetch,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isExpandAllBills,
  setIsExpandAllBills,
  viewer,
}) => {
  return (
    <React.Fragment>
      <FinalizedInvoiceCallout
        invoice={project.mainMoveInvoice}
        refetch={refetch}
        isAuthorizedAccountingActions={viewer.isAuthorizedAccountingActions}
      />
      <ProjectBills
        project={project}
        refetch={refetch}
        descriptionField={descriptionField}
        getTruncatedKey={getTruncatedKey}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        isExpandAllBills={isExpandAllBills}
        setIsExpandAllBills={setIsExpandAllBills}
      />
    </React.Fragment>
  );
};

const ProjectBillingBlockContent = ({
  project,
  refetch,
  urlFilters,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  viewer,
}) => {
  const {isEnabledSalesTaxAdjustments} = project.organization.features;

  // Set state for truncated and enabledToggle after data has been queried.
  useMountEffect(() => {
    const newTruncated = {};
    const newEnabledToggle = {};

    project.bills.forEach((bill) => {
      bill.billItems.forEach((billItem) => {
        const truncatedKey = getTruncatedKey({billItemId: billItem.id});

        // Whenever component is mounted, initialize expand/collapse state for new bill item
        // descriptions and keep existing value for existing descriptions.
        if (Object.prototype.hasOwnProperty.call(truncated, truncatedKey)) {
          newTruncated[truncatedKey] = truncated[truncatedKey];
        } else {
          newTruncated[truncatedKey] = true;
        }

        if (Object.prototype.hasOwnProperty.call(enabledToggle, truncatedKey)) {
          newEnabledToggle[truncatedKey] = enabledToggle[truncatedKey];
        } else {
          newEnabledToggle[truncatedKey] = false;
        }
      });
    });

    setTruncated(newTruncated);
    setEnabledToggle(newEnabledToggle);
  });

  return (
    <ActionPanel
      BodyComponent={BodyComponent}
      bodyComponentProps={{
        project,
        refetch,
        urlFilters,
        descriptionField,
        getTruncatedKey,
        truncated,
        setTruncated,
        enabledToggle,
        setEnabledToggle,
        viewer,
      }}
      ActionButtonComponent={BillingActionButtons}
      actionButtonComponentProps={{
        project,
        refetch,
        isVisibleInvoiceActions: isEnabledSalesTaxAdjustments,
        invoiceActionSections: ['BILLING', 'PAYMENTS'],
      }}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectBillingBlockContentMobile = ({
  project,
  refetch,
  urlFilters,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isExpandAllBills,
  setIsExpandAllBills,
  viewer,
}) => {
  const {isEnabledSalesTaxAdjustments} = project.organization.features;

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Billing`}
        ActionsComponent={BillingActionButtons}
        actionsComponentProps={{
          project,
          refetch,
          isVisibleInvoiceActions: isEnabledSalesTaxAdjustments,
          invoiceActionSections: ['BILLING', 'PAYMENTS'],
        }}
      />
      <BodyComponent
        project={project}
        refetch={refetch}
        urlFilters={urlFilters}
        descriptionField={descriptionField}
        getTruncatedKey={getTruncatedKey}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        isExpandAllBills={isExpandAllBills}
        setIsExpandAllBills={setIsExpandAllBills}
        viewer={viewer}
      />
    </React.Fragment>
  );
};

const ProjectBillingBlock = ({project, handleSetPositionY, urlFilters, index}) => {
  const responsive = useResponsive();

  // Note(shawn): Defining expand/collapse state for bill item descriptions here to avoid state
  // being reset on remounts whenever page height changes.
  const descriptionField = 'description';
  const getTruncatedKey = ({billItemId}) =>
    `BILL_ITEM_${billItemId}_${descriptionField.toUpperCase()}`;
  const [truncated, setTruncated] = useState({});
  const [enabledToggle, setEnabledToggle] = useState({});
  const [isExpandAllBills, setIsExpandAllBills] = useState(true);

  return (
    <ProjectBlockWrapper
      key={`${project.billsHash}_${project.totalRevenue}_${project.valuationCoverage?.updatedAt}`}
      index={index}
      query={ProjectBillingBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
    >
      {({data, refetch}) => {
        const {project, viewer} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectBillingBlockContent
                project={project}
                refetch={refetch}
                urlFilters={urlFilters}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                viewer={viewer}
              />
            ) : (
              <ProjectBillingBlockContentMobile
                project={project}
                refetch={refetch}
                urlFilters={urlFilters}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                isExpandAllBills={isExpandAllBills}
                setIsExpandAllBills={setIsExpandAllBills}
                viewer={viewer}
              />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingBlock.listener = gql`
  fragment ProjectBillingBlock_listener on Project {
    id
    billsHash
    totalRevenue
    valuationCoverage {
      id
      updatedAt
    }
  }
`;

ProjectBillingBlock.fragment = gql`
  ${ProjectBillingBlock.listener}
  fragment ProjectBillingBlock on Project {
    id
    uuid
    ...ProjectBillingBlock_listener
  }
`;

ProjectBillingBlock.query = gql`
  ${BillingActionButtons.fragment}
  ${FinalizedInvoiceCallout.fragment}
  ${ProjectBillingBlock.listener}
  ${ProjectBills.fragment}
  ${ProjectHeader.listener}
  query ProjectBillingBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      organization {
        id
        slug
        features {
          isEnabledSalesTaxAdjustments: isEnabled(feature: "SALES_TAX_ADJUSTMENTS")
        }
      }
      bills {
        id
        billItems {
          id
        }
      }
      mainMoveInvoice {
        id
        ...FinalizedInvoiceCallout
      }
      ...BillingActionButtons
      ...ProjectBills
      ...ProjectBillingBlock_listener
      ...ProjectHeader_listener
    }
    viewer {
      id
      isAuthorizedAccountingActions
    }
  }
`;

export default ProjectBillingBlock;
