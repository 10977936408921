// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Popover} from '@supermove/components';
import {FilteredClientsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback, useModal, usePopover} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import useManagerRestriction from 'modules/App/hooks/useManagerRestriction';
import ClientsFilterPopover from 'modules/Client/components/ClientsFilterPopover';
import NewClientModal from 'modules/Client/components/NewClientModal';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex: 3;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TabsContainer = Styled.View`
`;

const TabHeader = ({activeTabKey, clientCountByCategory, form, organization}) => {
  const {allCount, movesCount, storageCount} = clientCountByCategory;
  const tabDefinitions = [
    {
      key: _.lowerCase(ProjectTypeCategory.ALL),
      label: 'All',
      count: allCount,
      category: `${_.lowerCase(ProjectTypeCategory.ALL)}`,
    },
    {
      key: _.lowerCase(ProjectTypeCategory.MOVE),
      label: 'Moves',
      count: movesCount,
      category: 'moves',
    },
    ...List.insertIf(organization.settings.isStorageEnabled, {
      key: _.lowerCase(ProjectTypeCategory.STORAGE),
      label: 'Storage',
      count: storageCount,
      category: `${_.lowerCase(ProjectTypeCategory.STORAGE)}`,
    }),
  ];
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.category === activeTabKey,
  );

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({category}) =>
          form.setFieldValue('filteredClientsForm.category', category)
        }
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const ClientsFilterButton = ({form, organization}) => {
  const {isRestricted, viewerId} = useManagerRestriction({
    isBypassed: organization.features.isEnabledSalespersonViewAllProjects,
    handleRestriction: (viewerId) => {
      form.setFieldValue('filteredClientsForm.salespersonIds', [viewerId]);
    },
  });

  const clientsFilterPopover = usePopover({name: 'Clients Filter Popover'});
  // Determine number of filters applied
  const numberOfFiltersApplied = FilteredClientsForm.getNumberOfFiltersApplied({
    form,
    organization,
    isRestricted,
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={clientsFilterPopover.ref}>
        <SecondaryButton
          text={numberOfFiltersApplied === 0 ? 'Filter' : `Filter (${numberOfFiltersApplied})`}
          iconLeft={Icon.Filter}
          onPress={clientsFilterPopover.handleToggle}
        />
      </Popover.Content>
      <ClientsFilterPopover
        organization={organization}
        form={form}
        popover={clientsFilterPopover}
        isRestricted={isRestricted}
        viewerId={viewerId}
      />
    </React.Fragment>
  );
};

const ClientsFilters = ({form, organization, refetch, clientCountByCategory}) => {
  const newClientModal = useModal({name: 'New Client Modal'});
  const {params} = useNavigationDOM();

  const handleChangeSearch = useDebouncedCallback(
    (input) => form.setFieldValue('filteredClientsForm.searchQuery', input),
    250,
    {leading: true},
  );

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by client name or project details'
            style={{width: '348px'}}
            defaultValue={_.get(form.values, 'filteredClientsForm.searchQuery')}
            isClearable
          />
          <Space width={12} />
          <ClientsFilterButton organization={organization} form={form} />
        </LeftContainer>
        <RightContainer>
          <Space width={8} />
          <Button text={`New Client`} iconLeft={Icon.Plus} onPress={newClientModal.handleOpen} />
        </RightContainer>
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        organization={organization}
        activeTabKey={params.category}
        clientCountByCategory={clientCountByCategory}
        form={form}
      />
      <NewClientModal
        key={newClientModal.key}
        isOpen={newClientModal.isOpen}
        handleClose={newClientModal.handleClose}
        refetch={refetch}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientsFilters.fragment = gql`
  ${ClientsFilterPopover.fragment}
  fragment ClientsFilters on Organization {
    id
    settings {
      id
      isStorageEnabled
    }
    features {
      isEnabledSalespersonViewAllProjects: isEnabled(feature: "SALESPERSON_VIEW_ALL_PROJECTS")
    }
    ...ClientsFilterPopover
  }
`;

export default ClientsFilters;
