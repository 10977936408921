// Libraries
import React from 'react';

// App
import {Space, Styled} from '@supermove/components';
import {useResponsive, Responsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// Supermove
import Switch from '@shared/design/components/Switch';

const Container = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  padding-horizontal: 16px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  width: 100%;
  min-width: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? '600' : '200')}px;
  max-width: 1200px;
`;

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: ${({responsive}: {responsive: Responsive}) => (responsive.desktop ? 'row' : 'column')};
`;

const Name = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${({color}: {color: string}) => color};
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}: {color: string}) => color};
`;

const Cell = Styled.View``;

interface SettingsSectionOptionProps {
  name: string;
  description?: string;
  isEnabled?: boolean;
  isDisabled?: boolean;
  isDisabledToggle?: boolean;
  onPress?: () => void;
  ActionButtonComponent?: React.ComponentType<any>;
  actionButtonComponentProps?: any;
}

const SettingsSectionOption: React.FC<SettingsSectionOptionProps> = ({
  name,
  description = '',
  isEnabled = false,
  isDisabled = false,
  isDisabledToggle,
  onPress,
  ActionButtonComponent,
  actionButtonComponentProps = {},
}) => {
  const responsive: Responsive = useResponsive();
  return (
    <Container responsive={responsive}>
      <ContentContainer responsive={responsive}>
        <Cell style={{flex: responsive.desktop ? 2 : 'auto'}}>
          <Name
            responsive={responsive}
            color={isDisabled ? colors.gray.tertiary : colors.gray.primary}
          >
            {name}
          </Name>
        </Cell>
        <Space width={24} height={12} />
        <Cell style={{flex: responsive.desktop ? 3 : 'auto'}}>
          {!!description && (
            <Description
              responsive={responsive}
              color={isDisabled ? colors.gray.tertiary : colors.gray.secondary}
            >
              {description}
            </Description>
          )}
          {ActionButtonComponent && (
            <React.Fragment>
              <Space height={12} />
              <ActionButtonComponent {...actionButtonComponentProps} />
            </React.Fragment>
          )}
        </Cell>
      </ContentContainer>
      <Space width={24} />
      <Cell
        style={{
          width: responsive.desktop
            ? Switch.DIMENSIONS.MEDIUM.OUTER_OVAL_WIDTH
            : Switch.DIMENSIONS.LARGE.OUTER_OVAL_WIDTH,
        }}
      >
        {(onPress || isDisabledToggle) && (
          <Switch
            isOn={isEnabled}
            disabled={isDisabled || isDisabledToggle}
            onChange={onPress}
            size={responsive.desktop ? Switch.SIZE.MEDIUM : Switch.SIZE.LARGE}
            isMobile={!responsive.desktop}
          />
        )}
      </Cell>
    </Container>
  );
};

export default SettingsSectionOption;
